<template>
  <div class="translate-block">
    <div class="translate-trigger"
         v-tooltip="{ text: active ? 'Hide translation' : 'Translate all keywords into English', position: 'bottom', classes: ['no-wrap-text'] }"
         :class="{active: active}"
         @click="translateClickHandler">
      <svg-icon icon="translate"/>
    </div>
  </div>

</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: "TranslateTool",
  props: {
    active: {
      type: Boolean,
    },
  },
  emits: ['translate-clicked'],
  data() {
    return {}
  },
  methods: {
    translateClickHandler(val) {
      this.$emit('translate-clicked');
    }
  }
})
</script>

<style lang="scss" src="./styles.scss" scoped></style>