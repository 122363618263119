<template>
  <the-new-header>
    <template v-if="!isMobile"
              v-slot:main-block>
      <template v-if="!isTablet">
        <add-entity-block @add-app-click-handler="addModalAppClickHandler"
                          v-if="userSubscription?.has_overhead_limits === false"
                          :apps-list="myAppsList"
                          :is-mobile="isMobile"
                          :is-tablet="isTablet"
                          header-title="My Apps"/>
        <h1 class="page-title"
            v-else>
          My Apps
        </h1>
      </template>
      <template v-if="isTablet">
        <h1 class="page-title">
          My Apps
        </h1>
      </template>

    </template>
  </the-new-header>
  <div class="page-content">
    <slot name="sidebar"></slot>
    <main :class="[{'collapsed': sidebarIsCollapsed}, {'full-width': isMobile}]">

      <div class="new-container"
           v-if="overheadType === 'appsOverhead'">
        <user-limits-exceeded>
          You have too many applications .
          Please select applications that you would like to delete 🤔 or <a
         class="update-plan-link" :href="subscriptionRoute()">update your plan</a> 💳.
        </user-limits-exceeded>
      </div>

      <div class="new-container"
           v-if="overheadType && overheadType !== 'appsOverhead'">
        <user-limits-exceeded>
          You have exceeded the limits of keywords and competitors for your account. Please, delete some keywords and
          competitors to fit within the current account limits.
        </user-limits-exceeded>
      </div>

      <div class="my-apps-page general-page-content">

        <div v-if="appsLoaded"
             class="new-container grid-and-trigger-wrap">
          <template v-if="isMobile || isTablet">
            <add-entity-block @add-app-click-handler="addModalAppClickHandler"
                              v-if="userSubscription?.has_overhead_limits === false"
                              :is-mobile="isMobile"
                              :is-tablet="isTablet"
                              header-title="My Apps"/>

            <h1 class="page-title mobile-visible"
                v-else>
              My Apps
            </h1>
          </template>

          <div v-if="showAppsGrid"
               class="billets-grid two"
               :class="{between : this.myApps && Object.values(this.myApps)?.length >= 3}">

            <app-billet v-for="item in myApps"
                        :key="item.id"
                        @app-clicked="appClickHandler(item)"
                        @delete-app-clicked="deleteAppHandler"
                        :has-overhead="userSubscription?.has_overhead_limits && overheadType === 'appsOverhead'"
                        :current-country="currentCountry"
                        :item-data="item">

              <button v-if="overheadType === 'appsOverhead'"
                      type="button"
                      class="btn btn-standard select-btn"
                      :class="{'outline': isSelected(item?.id) === false}"
                      @click="selectToDeleteClicked(item?.id)">
                <template v-if="isSelected(item?.id)">Unselect</template>
                <template v-else>Select</template>
              </button>

            </app-billet>

            <div v-if="overheadType === 'appsOverhead'"
                 class="overhead-delete-details">
              <div class="delete-counter">Apps to delete -
                {{ indexesToDelete?.length }}/{{ userLimits?.max_apps?.used - userLimits?.max_apps?.total }}
              </div>

              <base-button
               v-if="(userLimits?.max_apps?.used - userLimits?.max_apps?.total) <= indexesToDelete?.length"
               height="40px"
               width="200px"
               font-size="15px"
               border-radius="4px"
               :mode="'btn-standard delete-btn'"
               @click="deleteAppsButtonClicked">Delete
              </base-button>

              <base-button v-else
                           height="40px"
                           width="200px"
                           font-size="15px"
                           border-radius="4px"
                           :mode="'btn-standard btn-disabled'">Delete
              </base-button>
            </div>

          </div>
          <div v-else
               class="no-data-block">
            <div class="content">
              <span class="text">Add your first app to start working with tools</span>
              <div class="sub-text">
                To make search results more specific, please use the source and country filters.
              </div>
              <div class="no-data-image">
                <img src="@/assets/images/no-data-image.png"
                     alt="No data image"/>
              </div>
            </div>
          </div>

          <custom-modal :opened="showDeleteAppModal"
                        :max-width="420"
                        class-name="delete-app-modal"
                        @closeModal="closeModal('showDeleteAppModal')">
            <div class="modal-message">Do you really want to delete this application?</div>
            <template v-slot:footer-action>
              <div class="btn-block action-btns">
                <base-button mode="outline"
                             height="46px"
                             @click="closeModal('showDeleteAppModal')">Cancel
                </base-button>

                <base-button height="40px"
                             width="180px"
                             @click="deleteAppById">Yes, Delete
                </base-button>
              </div>
            </template>
          </custom-modal>

          <custom-modal :opened="afterRegistrationModal"
                        :max-width="464"
                        class-name="success-registration-modal"
                        @closeModal="afterRegistrationModalClosed">
            <div class="modal-message">

              <div class="image">
                <img src="@/assets/images/successful_illustration.png"
                     alt="Successful registration image"/>
              </div>
              <div class="modal-subtitle">
                Congratulations!<br>
                Your email has been confirmed.
              </div>
              <div class="btn-block">
                <base-button height="40px"
                             width="140px"
                             border-radius="4px"
                             @click="afterRegistrationModalClosed">Go to tools
                </base-button>
              </div>
            </div>
          </custom-modal>

          <custom-modal :opened="showBatchDeleteAppModal"
                        :max-width="520"
                        class-name="batch-delete-app-modal"
                        @closeModal="closeModal('showBatchDeleteAppModal')">
            <div class="modal-message" v-if="indexesToDelete?.length === 1">⚠️🗑️ Are you sure you want to delete this
              application?
            </div>
            <div class="modal-message" v-else>⚠️🗑️ Are you sure you want to delete these applications?</div>

            <div class="subtitle">This action cannot be undone, and all associated data will be lost.</div>

            <template v-if="deleteModalLoading">
              <div class="preloader-wrap">
                <preloader-table-small loader-size="50px"></preloader-table-small>
              </div>
            </template>
            <template v-else>
              <div class="apps-lists">
                <div class="apps-list-block">
                  <table>
                    <thead>
                    <tr>
                      <th class="app-name left">To be deleted</th>
                      <th class="default">Keywords</th>
                      <th class="default">Competitors</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in modalAppsToDelete">
                      <td class="app-name">
                        <div class="app-details">
                          <div class="app-logo">
                            <img :src="item?.logo"
                                 alt="App logo"
                                 width="24"
                                 height="24">
                          </div>
                          <div class="app-title">{{ item?.title }}</div>
                        </div>
                      </td>
                      <td class="default">
                        <div class="app-detail-item">{{ item?.keywords }}</div>
                      </td>
                      <td class="default">
                        <div class="app-detail-item">{{ item?.competitors }}</div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="apps-list-block"
                     v-if="modalAppsToSave?.length > 0">
                  <table>
                    <thead>
                    <tr>
                      <th class="app-name left">Saved applications</th>
                      <th class="default"></th>
                      <th class="default"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in modalAppsToSave">
                      <td class="app-name">
                        <div class="app-details">
                          <div class="app-logo">
                            <img :src="item?.logo"
                                 alt="App logo"
                                 width="24"
                                 height="24">
                          </div>
                          <div class="app-title">{{ item?.title }}</div>
                        </div>
                      </td>
                      <td class="default"></td>
                      <td class="default"></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>

            <template v-slot:footer-action>
              <div class="btn-block action-btns">
                <base-button height="40px"
                             width="144px"
                             font-size="15px"
                             border-radius="4px"
                             :mode="'btn-standard outline'"
                             @click="closeModal('showBatchDeleteAppModal')">Cancel
                </base-button>

                <base-button height="40px"
                             width="144px"
                             font-size="15px"
                             border-radius="4px"
                             :mode="'btn-standard outline'"
                             @click="deleteSelectedApps">Delete
                </base-button>

                <base-button height="40px"
                             width="144px"
                             font-size="15px"
                             border-radius="4px"
                             @click="updatePlanHandler"
                             :mode="'btn-standard'">Pay now
                </base-button>

              </div>
            </template>
          </custom-modal>
        </div>

        <template v-else>
          <progress-indicator :loaded="loaded">{{ loaderText }}</progress-indicator>
        </template>
      </div>
    </main>

    <template v-if="currentModalComponent !== ''">
      <component :is="currentModalComponent"
                 :plan-name="userSubscription?.plan_name"
                 :plan-key="userSubscription?.last_plan_key"
                 :open-show-modal-outside="showOverheadModal">

        <button type="button"
                @click="changePlanHandler"
                class="btn btn-standard outline">
          Change Plan
        </button>

        <button type="button"
                v-if="userSubscription?.last_plan_key !== null && userSubscription?.last_plan_key !== 'null'"
                @click="updatePlanHandler"
                class="btn btn-standard">
          Update Payment
        </button>
      </component>
    </template>

  </div>
</template>

<script>
import AppBillet from "@/components/Billets/AppBillet/index";
import AddAppModalForm from "./components/AddAppModalForm/index";
import ProgressIndicator from "@/components/UI/ProgressIndicator/index";
import {mapGetters} from 'vuex';
import {httpRequest} from "@/api";
import {deleteCookie, getCookie, setCookie} from "@/utils/utils";
import {AppsWithAdditionalDataObj, UserAppsArrayCreator} from "@/utils/factories";
import UpgradeSuccessfullModal from "@/views/MyApps/components/UpgradeSuccessfullModal/index.vue";
import CongratulationsModal from "@/views/MyApps/components/CongratulationsModal/index.vue";
import SelectActionsModal from "@/views/MyApps/components/SelectActionsModal/index.vue";
import OverheadAppsModal from "@/views/MyApps/components/OverheadAppsModal/index.vue";
import UserLimitsExceeded from "@/components/UI/UserLimitsExceeded/index.vue";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import TheNewHeader from "@/components/TheNewHeader/index.vue";

export default {
  name: 'MyApps',
  components: {
    TheNewHeader,
    'app-billet': AppBillet,
    'add-app-modal-form': AddAppModalForm,
    'progress-indicator': ProgressIndicator,
    'upgrade': UpgradeSuccessfullModal,
    'congrats': CongratulationsModal,
    'select-actions': SelectActionsModal,
    'apps-overhead': OverheadAppsModal,
    UserLimitsExceeded,
    'preloader-table-small': PreloaderTableSmall,
  },
  data() {
    return {
      loaderText: '',
      showDeleteAppModal: false,
      showAddAppModal: false,
      showBatchDeleteAppModal: false,
      loaded: false,
      appsLoaded: false,
      env: process.env.VUE_APP_URL,
      myAppsList: [],
      appIdToDelete: null,
      betaTesterCookie: null,
      currentModalComponent: '',
      indexesToDelete: [],
      modalAppsToDelete: [],
      modalAppsToSave: [],
      deleteModalLoading: false,
      afterRegistrationModal: false,
      myApps: [],
    }
  },
  methods: {
    closeModal(modalName) {
      this[modalName] = false;
      document.body.style.overflow = 'auto';
    },
    async setAppsList() {
      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.MY_APPLICATIONS_NEW);

      this.myApps = UserAppsArrayCreator(result) ?? [];
      this.$store.dispatch('SET_MY_APPS', UserAppsArrayCreator(result) ?? []);
    },
    async getAppsList(getDataFromStore = false) {
      this.appsLoaded = false;
      this.loaded = false;
      let result = null;

      if (getDataFromStore) {
        this.myApps = [...this.myApps];
      } else {
        result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.MY_APPLICATIONS_NEW);
        this.$store.dispatch('SET_MY_APPS', UserAppsArrayCreator(result) ?? []);
        this.myApps = UserAppsArrayCreator(result) ?? [];
      }


      this.loaded = true;
      setTimeout(() => {
        this.appsLoaded = true;
      }, 300);
    },
    deleteAppHandler(id) {
      // if (this.userSubscription?.plan_name === 'Free') {
      //   this.$store.dispatch('INVOKE_USER_LIMITS_MANUAL');
      //   return;
      // }

      this.showDeleteAppModal = true;
      this.appIdToDelete = id;
    },
    async deleteAppById() {
      let formData = new FormData();
      formData.append('_method', 'delete');
      const result = await httpRequest('DELETE', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.MY_APPLICATIONS + '/' + this.appIdToDelete);
      const currentAppId = getCookie('currentAppId');

      if (+currentAppId === this.appIdToDelete) {
        deleteCookie('currentAppId');
        location.reload();
      } else {
        this.$store.dispatch('UPDATE_USER_LIMITS');
        await this.getAppsList();
      }

      this.closeModal('showDeleteAppModal');
    },
    async appClickHandler(item) {
      this.appsLoaded = false;
      this.$store.dispatch('SET_CURRENT_APP_RELOADED', true);
      this.$store.dispatch('SET_CURRENT_APP_ID', item.id);
      this.$store.dispatch('SET_CURRENT_APP', item);
      setCookie('currentAppId', item.id, null, 7);
      localStorage.setItem('doNotUpdatePage', 'true');
      this.appsLoaded = true;
      if (this.userSubscription?.has_overhead_limits === false || this.userSubscription?.has_overhead_limits === undefined) {
        await this.$router.push('/dashboard/overview');
      } else {
        await this.$router.push('/');
      }
    },
    async addModalAppClickHandler(app) {
      let formData = new FormData();
      formData.append('application_id', app.application_id);
      formData.append('country_code', app.country_code);
      const result = await httpRequest('POST', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.MY_APPLICATIONS, formData);

      if (result?.status === 403) {
        this.closeModal('showAddAppModal');
        this.$store.dispatch('UPDATE_USER_LIMITS');
        return;
      }

      const fullCountriesList = app?.fullCountriesList;
      const currCountryArr = fullCountriesList.filter(country => country.code === app.country_code);
      const currAppArray = result?.applications?.filter(application => application.id === app.application_id);
      const currApp = currAppArray[0];
      const currCountryById = currCountryArr.filter(country => country?.store_id === currApp?.store_id);
      const currCountryByIdApp = currCountryById[0];

      if (currCountryByIdApp?.id) {
        await this.setCurrentLatestCountry(currApp.id, currCountryByIdApp.id);
      }

      this.$store.dispatch('SET_CURRENT_APP_RELOADED', true);
      this.$store.dispatch('SET_CURRENT_APP_ID', currApp.id);
      this.$store.dispatch('SET_CURRENT_APP', currApp);
      setCookie('currentAppId', currApp.id, null, 7);
      localStorage.setItem('doNotUpdatePage', 'true');
      await this.getAppsList();
    },
    async setCurrentLatestCountry(appId, countryId) {
      const url = process.env.VUE_APP_API_URL + 'api/profiles/applications/' + appId + '/' + countryId + '/used';
      await httpRequest('POST', url);
    },
    async setLatestCountry() {
      const currentAppId = getCookie('currentAppId');
      if (!currentAppId || currentAppId === 'undefined') {
        return;
      }
      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + 'api/profiles/applications/' + getCookie('currentAppId') + '/countries/latest');
      let country;
      if (result?.country) {
        country = {...result?.country};
      } else {
        country = {
          code: "US",
          id: 103,
          name: "United States",
        };
      }

      this.$store.dispatch('SET_CURRENT_COUNTRY', country);
    },
    subscriptionRoute() {
      const path = 'subscription/update';
      return this.env + path + '?plan=' + this.userSubscription?.last_plan_key;
    },
    selectToDeleteClicked(id) {
      if (this.indexesToDelete?.includes(id)) {
        const deleteIdx = this.indexesToDelete.findIndex(item => item === id);
        const tempIdxArray = [...this.indexesToDelete];

        if (deleteIdx >= 0) {
          tempIdxArray.splice(deleteIdx, 1);
          this.indexesToDelete = [...tempIdxArray]
        }

      } else {
        this.indexesToDelete.push(id);
      }
    },
    isSelected(id) {
      if (this.indexesToDelete?.includes(id)) {
        return true;
      } else {
        return false;
      }
    },
    async deleteSelectedApps() {
      let formData = new FormData();

      for (const key in this.indexesToDelete) {
        formData.append('app_ids[]', this.indexesToDelete[key]);
      }
      const result = await httpRequest('POST', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.BATCH_DELETE, formData);
      this.closeModal('showBatchDeleteAppModal');
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

      if (this.indexesToDelete?.includes(this.currentApp?.id)) {
        deleteCookie('currentAppId');
        location.reload();
      }

      await this.getAppsList();
      this.$store.dispatch('UPDATE_USER_LIMITS');
    },
    changePlanHandler() {
      const cookieDomain = process.env.VUE_APP_COOKIE_DOMAIN;
      const days = 0.5;
      setCookie('userPlanUpgrade', true, cookieDomain, days);
      window.location.href = this.env + 'pricing';
    },
    updatePlanHandler() {
      const path = 'subscription';
      const cookieDomain = process.env.VUE_APP_COOKIE_DOMAIN;
      const days = 0.5;
      setCookie('userPlanUpgrade', true, cookieDomain, days);
      window.location.href = this.env + path + '?plan=' + this.userSubscription?.last_plan_key;
    },
    async deleteAppsButtonClicked() {
      this.deleteModalLoading = true;
      this.showBatchDeleteAppModal = true;
      let formData = new FormData();
      let url = '';

      for (const key in this.indexesToDelete) {
        formData.append('app_ids[]', this.indexesToDelete[key]);
        url += '&app_ids[]=' + this.indexesToDelete[key];
      }

      let myApps = Object?.values(this.myApps);
      let notSelectedApps = myApps?.filter(item => !this.indexesToDelete?.includes(item?.id));
      let queryString = url.charAt(0).replace('&', '?') + url.slice(1);
      const appsList = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.BATCH_INFO + queryString);

      this.deleteModalLoading = false;
      this.modalAppsToDelete = AppsWithAdditionalDataObj(appsList?.by_app, this.myApps);
      this.modalAppsToSave = AppsWithAdditionalDataObj(notSelectedApps, this.myApps);
    },
    afterRegistrationModalClosed() {
      this.afterRegistrationModal = false;
      localStorage.setItem('onboardingModalShown', 'true');
    },
  },
  async mounted() {
    if (this.$route.query?.after_payment_status) {
      try {
        if (this.$route.query?.after_payment_status === 'upgrade') {
          this.currentModalComponent = 'congrats';
          deleteCookie('userPlanUpgrade');
        } else if (this.$route.query?.after_payment_status === 'trial') {
          this.currentModalComponent = 'congrats';
          deleteCookie('userPlanUpgrade');
        } else if (this.$route.query?.after_payment_status === 'continue_plan') {
          this.currentModalComponent = 'congrats';
          deleteCookie('userPlanUpgrade');
        } else if (this.$route.query?.after_payment_status === 'downgrade') {
          this.currentModalComponent = 'upgrade';
          deleteCookie('userPlanUpgrade');
        }
      } catch (e) {
        console.error(e);
      }
    } else if (this.userSubscription?.has_overhead_limits === true && this.overheadType === 'appsOverhead') {
      this.currentModalComponent = 'select-actions';
    } else if (this.userSubscription?.has_overhead_limits === true && this.overheadType !== 'appsOverhead') {
      // this.currentModalComponent = 'select-actions';
    }
    this.appsLoaded = false;
    await this.setAppsList();
    const currentAppId = getCookie('currentAppId');

    this.betaTesterCookie = getCookie('betaTesterCookie');

    if (!currentAppId && currentAppId !== 'undefined') {
      this.$store.dispatch('UPDATE_USER_LIMITS');

      let myApps = null;
      if (this.myApps?.isArray) {
        myApps = [...this.myApps];
      } else {
        if (Object?.values(this.myApps)) {
          myApps = Object.values(this.myApps)
        }
      }

      this.myApps = [...myApps];

      if (this.myApps?.length > 0) {
        setCookie('currentAppId', this.myApps[0]?.id, null, 7);
        await this.setLatestCountry();
        location.reload();
      }
      // location.reload();
    }

    this.appsLoaded = true;

    const onboardingModalShown = localStorage.getItem('onboardingModalShown');

    if (this.userSubscription?.plan_name === 'Free'
     && this.userLimits?.max_competitors?.used === 0
     && this.userLimits?.max_keywords?.used === 0
     && this.userLimits?.max_apps?.used === 0
     && !onboardingModalShown) {
      setTimeout(() => {
        this.afterRegistrationModal = true;
      }, 750);
    }
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'isTablet',
      'sidebarIsCollapsed',
      'currentApp',
      'currentCountry',
      // 'myApps',
      'userLimits',
      'userSubscription',
      'showOverheadModal',
    ]),
    overheadType() {
      if (this.userSubscription?.has_overhead_limits && (this.userLimits?.max_apps?.used > this.userLimits?.max_apps?.total)) {
        return 'appsOverhead';
      } else if (this.userSubscription?.has_overhead_limits && (this.userLimits?.max_keywords?.used > this.userLimits?.max_keywords?.total)) {
        return 'keywordsOverhead';
      } else if (this.userSubscription?.has_overhead_limits && (this.userLimits?.max_competitors?.used > this.userLimits?.max_competitors?.total)) {
        return 'competitorsOverhead';
      } else {
        return false;
      }
    },
    showAppsGrid() {
      return this.myApps && Object.values(this.myApps)?.length > 0;
    },
  },
  watch: {
    async myApps(newVal, oldVal) {
      this.myAppsList = [...this.myApps];
    },
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>