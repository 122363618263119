<script>
import {cropImage, shortenDigits} from "@/utils/utils";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import BasicSearchInput from "@/components/UI/BasicSearchInput/index.vue";
import AdaptiveDataTable from "@/components/DataTables/AdaptiveDataTable/index.vue";
import TableCheckbox from "@/components/UI/TableCheckbox/index.vue";
import AppImage from "@/components/UI/ImageLazyLoad/index.vue";
import RatingTool from "@/components/DataTables/Tools/RatingTool/index.vue";
import VotedTool from "@/components/DataTables/Tools/VotedTool/index.vue";
import SimpleInfoTable from "../../../components/SimpleInfoTable/SimpleInfoTable.vue";
import {shortUnits} from "@/utils/utils";
import competitorsFinder from "../../../api/modules/competitors-finder";
import ProgressIndicator from "../../../components/UI/ProgressIndicator/index.vue";
import liveSearch from "../../../api/modules/live-search";
import customCompetitors from "../../../api/modules/custom-competitors";
import Tooltip from "../../../components/UI/Tooltip/index.vue";
import EmptyResult from "../../../components/UI/EmptyResult.vue";
import FiltersNew from "../../../components/UI/FiltersComponent/FiltersNew.vue";
import {mapGetters} from "vuex";

export default {
  name: "competitors_select_modal",
  props: {
    currentApp: {
      type: Object,
      default: null,
    },
    currentCountry: {
      type: Object,
      default: null,
    },
    oneSelectMode: {
      type: Boolean,
      default: false,
    },
    initCheckedCount: {
      type: Number,
      default: null,
    },
    maxCheckedCount: {
      type: Number,
      default: null,
    },
    initSelectEmit: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['save-and-continue'],
  data() {
    return {
      appsRegistry: {},

      competitorsObj: {},

      filtersState: null,

      filtersLimits: [],
      resetFilters: 0,
      initialActiveFiltersObj: {},
      appliedFiltersObject: {},

      searchKeywordsInput: '',

      sort: {},

      activeTab: 'my-competitors',
      recommendedLoaded: false,
      searchLoading: false,
      recommendedCompetitorsObj: null,
      searchCompetitorsObj: null,
      liveSearchInput: null,

      competitorsModalIsOpen: false,
    };
  },
  async created() {
    this.competitorsObj = await customCompetitors.fetchCompetitors(this.currentApp.id, this.currentCountry?.id, 1);

    this.competitorsObj.getCompetitors().forEach((item) => {
      this.appSetGetFromRegistry(item);
    });

    this.appliedFiltersObject = this.competitorsObj.getLatestFilterQ();
    delete this.appliedFiltersObject['filter[like][query]'];
    this.competitorsObj.competitors = this.competitorsObj.getCompetitorsFiltered(this.appliedFiltersObject);
    this.sort = this.competitorsObj.latestSort ?? {sort: 'rating', order: 'desc'};

    if (this.initCheckedCount !== null) {
      this.competitorsObj.initialData.forEach((item, index) => {
        if (index >= this.initCheckedCount) {
          item.checked = false;
        }
      });
    }

    if (this.initSelectEmit) {
      this.saveAndContinue();
    }

    await this.loadRecommended();
  },
  computed: {
    ...mapGetters([
      'userSubscription',
    ]),
    categoriesList() {
      let list = {};
      this.finalObj.initialData.forEach((item) => {
        if (item.category) {
          list[item.category] = {
            presetValue: item.category,
            filterValue: item.category.toLowerCase(),
            selected: true,
          };
        }
      });

      return Object.values(list);
    },
    filtersData() {
      let cats = this.categoriesList;

      return [
        {
          type: 'dropdown-multi',
          title: 'Category',
          filter: 'categories_names',
          value: 'all',
          isInitiallyActive: false,
          defaultData: cats,
          presetsData: cats
        },
        {
          type: 'range',
          title: 'Rate',
          filter: 'range_rating',
          min: (0).toFixed(1),
          max: (5).toFixed(1),
          minLimit: 0,
          maxLimit: 5,
          isInitiallyActive: false,
          presetsData: null,
        },
        {
          type: 'range',
          title: 'Ratings',
          filter: 'range_votes_count',
          min: 0,
          max: 9999999,
          minLimit: 0,
          maxLimit: 99999999,
          isInitiallyActive: false,
          presetsData: null,
        },
      ];
    },
    searchQueryFilter() {
      return this.appliedFiltersObject['filter[like][query]'];
    },
    hasSearchQueryFilter() {
      return !!this.appliedFiltersObject['filter[like][query]'];
    },
    isFreeUser() {
      return this.userSubscription?.plan_name === 'Free';
    },
    hideInstalls() {
      return this.isFreeUser;
    },
    activeObj() {
      switch (this.activeTab) {
        case 'my-competitors':
          return this.competitorsObj;
        case 'recommended':
          return this.recommendedCompetitorsObj;
        case 'search':
          return this.searchCompetitorsObj;
      }
    },
    finalObj() {
      let apps = {};
      this.competitorsObj.initialData.forEach((item) => {
        apps[item.competitorId] = item;
      });
      if (this.recommendedCompetitorsObj) {
        this.recommendedCompetitorsObj.initialData.forEach((item) => {
          if (!apps[item.competitorId]) {
            apps[item.competitorId] = item;
          }
        });
      }
      if (this.searchCompetitorsObj) {
        this.searchCompetitorsObj.initialData.forEach((item) => {
          if (!apps[item.competitorId]) {
            apps[item.competitorId] = item;
          }
        });
      }

      return new customCompetitors.customCompetitorsViewObject(this.competitorsObj.filterValueLimits, Object.values(apps));
    }
  },
  components: {
    FiltersNew,
    EmptyResult,
    Tooltip,
    ProgressIndicator,
    SimpleInfoTable,
    VotedTool,
    RatingTool,
    AppImage,
    AdaptiveDataTable,
    BasicSearchInput,
    SvgIcon,
    'data-table-checkbox': TableCheckbox,
  },
  methods: {
    shortenDigits,
    appSetGetFromRegistry(item) {
      if (!this.appsRegistry[item.competitorId]) {
        this.appsRegistry[item.competitorId] = item;
      }

      return this.appsRegistry[item.competitorId];
    },
    trClassCallback(item) {
      if (this.oneSelectMode) {
        return 'tr_shadow_hover cursor-pointer';
      }

      return '';
    },
    openModal() {
      this.competitorsModalIsOpen = true;
    },
    closeModal() {
      this.competitorsModalIsOpen = false;
    },
    shortUnits,
    saveAndContinue() {
      if (this.maxCheckedCount !== null) {
        if (this.finalObj.getCompetitorsCheckedOnlyInitial().length > this.maxCheckedCount) {
          return;
        }
      }

      this.$emit('save-and-continue', this.finalObj);
      this.competitorsModalIsOpen = false;
    },
    cropImage,
    competitorsItemClickHandler(item) {
      if (!this.oneSelectMode) {
        return;
      }

      this.$emit('save-and-continue', item);
      this.competitorsModalIsOpen = false;
    },
    async filtersApplied(e) {
      this.appliedFiltersObject = {...e};
      this.syncSearchWithFilter();
      this.filterCompetitors();

      this.filtersState = this.$refs.filters?.getState();
    },
    clearKeywordsSearchInput() {
      this.searchKeywordsInput = '';
    },
    searchKeywordsHandler(v) {
      this.searchKeywordsInput = v;
      this.syncSearchWithFilter();
      this.filterCompetitors();
    },
    syncSearchWithFilter() {
      this.appliedFiltersObject['filter[like][query]'] = this.searchKeywordsInput;
    },
    filterCompetitors() {
      if (!this.activeObj) {
        return;
      }
      let filterObj = {...this.appliedFiltersObject};
      if (this.activeTab === 'search') {
        delete filterObj['filter[like][query]'];
      }

      this.activeObj.competitors = this.activeObj.getCompetitorsFiltered(filterObj);
    },
    updateSort(sort) {
      this.sort = sort;
      this.competitorsObj.latestSort = sort;
      this.competitorsObj.sort(sort);
    },
    async handleChangeTab(tab) {
      this.activeTab = tab;

      this.filterCompetitors();
    },
    async loadRecommended() {
      if (!this.currentCountry?.code) {
        return;
      }

      let tmp = await competitorsFinder.findCompetitors(this.currentCountry.code, this.currentApp.id);
      tmp.uncheckAll();

      tmp.competitors = tmp.competitors.map((item) => {
        return this.appSetGetFromRegistry(item);
      });
      tmp.initialData = tmp.initialData.map((item) => {
        return this.appSetGetFromRegistry(item);
      })

      this.recommendedCompetitorsObj = tmp;
      this.recommendedLoaded = true;

      await this.filtersApplied({});
    },
    async handleLiveSearch() {
      let input = this.liveSearchInput;
      input = input.trim();
      input = input.toLowerCase();
      if (!input) {
        return;
      }

      this.searchLoading = true;
      this.searchCompetitorsObj = null;

      let tmp = await liveSearch.getKeywordTop(
          this.currentCountry.code,
          this.currentApp.store.key === 'APP_STORE' ? 'iphone' : 'mobile',
          this.currentApp.id,
          input,
          0,
          20,
          true,
          liveSearch.formatAsCustomCompetitors
      );

      tmp.uncheckAll();

      tmp.competitors = tmp.competitors.map((item) => {
        return this.appSetGetFromRegistry(item);
      });
      tmp.initialData = tmp.initialData.map((item) => {
        return this.appSetGetFromRegistry(item);
      })

      this.searchCompetitorsObj = tmp;

      this.searchLoading = false;

      await this.filtersApplied({});
      this.filterCompetitors();
    },
    hintSearch(text, query) {
      const start = text.toLowerCase().indexOf(query.toLowerCase());
      const end = start + query.length;

      return text.slice(0, start) + '<b>' + text.slice(start, end) + '</b>' + text.slice(end);
    }
  },
  watch: {
    currentCountry() {
      this.loadRecommended();
    }
  }
}
</script>

<template>
  <custom-modal :opened="competitorsModalIsOpen"
                :max-width="1130"
                @closeModal="closeModal"
  >
    <div class="modal-heading small-heading select-competitors-heading">
      <h3 v-if="!oneSelectMode">Select competitors you want to analyze</h3>
      <h3 v-else>Select competitor you want to analyze</h3>
    </div>
    <div class="modal-message textarea-block select-competitors-modal">
      <div>
        <div class="options-and-filter-block">
          <div class="search-and-filter-block mb-25">
            <div>
              <div class="filters-block">
                <filters-new :filters="filtersData" @apply-filters="filtersApplied" ref="filters"></filters-new>
              </div>
              <div class="selected-text" v-if="!oneSelectMode && activeObj">
                <span><b>{{ activeObj.getCompetitorsCheckedOnlyInitial().length }} of {{ activeObj.getTotalCount() }}</b> competitors selected<template
                    v-if="activeObj.competitors.length !== activeObj.getTotalCount()">, showed <b>{{ activeObj.competitors.length }}</b></template></span>
              </div>
              <div class="selected-text" v-else-if="activeObj">
                <span><b><template
                    v-if="activeObj.competitors.length !== activeObj.getTotalCount()">Showed <b>{{ activeObj.competitors.length }}</b> of </template>{{ activeObj.getTotalCount() }}</b> competitors</span>
              </div>
            </div>
            <div class="search-block-competitors" v-if="activeTab !== 'search'">
              <basic-search-input placeholder="Search"
                                  :hide-results-block="true"
                                  @search-input-changed="searchKeywordsHandler"
                                  :clear-input="searchKeywordsInput"
                                  :init-value="searchKeywordsInput">

                <template v-slot:search-icon>
                  <svg-icon v-if="searchKeywordsInput?.length === 0"
                            class="search-icon"
                            icon="search-solid"/>

                  <svg-icon v-else
                            @click="clearKeywordsSearchInput"
                            class="close-icon"
                            icon="close"/>
                </template>
              </basic-search-input>
            </div>
            <div class="search-block-competitors w100p" v-else>
              <input type="text" class="search-new-input"
                     placeholder="Enter most relevant keyword to start competitors research" v-model="liveSearchInput" @keyup.enter="handleLiveSearch">
              <div class="common-btn-purple ml-15" @click="handleLiveSearch">Search</div>
            </div>
          </div>
          <div class="display-flex f-space-between f-align-center">
            <div class="tabs-nav-block">
              <div :class="{'active':activeTab === 'my-competitors'}" @click="handleChangeTab('my-competitors')">My
                Competitors
                <template v-if="competitorsObj.getCompetitorsCheckedOnlyInitial().length">
                  ({{ competitorsObj.getCompetitorsCheckedOnlyInitial().length }})
                </template>
              </div>
              <div :class="{'active':activeTab === 'recommended'}" @click="handleChangeTab('recommended')">Recommended
                <template v-if="recommendedCompetitorsObj?.getCompetitorsCheckedOnlyInitial().length">
                  ({{ recommendedCompetitorsObj.getCompetitorsCheckedOnlyInitial().length }})
                </template>
              </div>
              <div :class="{'active':activeTab === 'search'}" @click="handleChangeTab('search')">Find New
                <template v-if="searchCompetitorsObj?.getCompetitorsCheckedOnlyInitial().length">
                  ({{ searchCompetitorsObj.getCompetitorsCheckedOnlyInitial().length }})
                </template>
              </div>
            </div>
            <div class="total_selected" v-if="!oneSelectMode">Selected
              ({{ finalObj.getCompetitorsCheckedOnlyInitial().length }}/{{ finalObj.getTotalCount() }})
            </div>
          </div>
        </div>
        <div class="competitors-table competitors-modal-table">
          <SimpleInfoTable
              :tr-class-callback="trClassCallback"
              :tr-click-callback="competitorsItemClickHandler"
              :multiselect="!oneSelectMode"
              :per-page="999"
              :show-pagination="false"
              :columns="{
                0: oneSelectMode
                  ? {header: 'App', style: {width: '100%', minWidth:'250px'}}
                  : {header: 'App', style: {width: '100%', minWidth:'250px', paddingLeft: 0}, headerStyle: {paddingLeft: 0}},
                1: {header: 'Rate', style: {'min-width': '100px'}, sortBy: 'rating'},
                2: {header: 'Ratings', style: {'min-width': '120px'}, sortBy: 'votesCount'},
                3: {header: 'Publisher', style: {'min-width': '150px', 'word-break': 'break-word'}, orientation: 'left'},
                4: {header: 'Category', style: {'min-width': '150px', 'word-break': 'break-word'}, orientation: 'left'},
                5: {header: 'Installs', style: {'min-width': '105px'}, sortBy: 'installs', hidden: hideInstalls},
                6: {header: 'Revenue', style: {'min-width': '105px'}, sortBy: 'revenue', hidden: hideInstalls || currentApp?.store?.key !== 'APP_STORE'},
              }"
              :default-sort-index="2"
              :default-sort-direction="'desc'"
              :items="activeObj?.competitors ?? []"
          >
            <template v-slot:headers="slotProps">
              <div>
                {{ slotProps.header }}
              </div>
            </template>
            <template v-slot:items-0="slotProps">
              <div class="display-flex f-align-center">
                <img :src="cropImage(slotProps.item.logo, '40x40', slotProps.item.store)" alt="">
                <div class="text-left">
                  <div class="ml-10 text-left font-w-500">
                    <span v-if="hasSearchQueryFilter"
                          v-html="hintSearch(slotProps.item.title, searchQueryFilter)">
                    </span>
                    <span v-else>
                      {{ slotProps.item.title }}
                    </span>
                  </div>
                  <div class="font-14 ml-10 text-left color-750">
                    <span v-html="hasSearchQueryFilter ? hintSearch(slotProps.item.subTitle, searchQueryFilter) : slotProps.item.subTitle">
                    </span>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:items-1="slotProps">
              <div class="display-flex f-align-center f-j-end">
                <rating-tool :rating="slotProps.item.rating"/>
              </div>
            </template>
            <template v-slot:items-2="slotProps">
              <div class="display-flex f-align-center f-j-end">
                {{ shortUnits(slotProps.item.votesCount) }}
              </div>
            </template>
            <template v-slot:items-3="slotProps">
              <div class="display-flex f-align-center f-j-start text-left">
                {{ slotProps.item.developerName }}
              </div>
            </template>
            <template v-slot:items-4="slotProps">
              <div class="display-flex f-align-center f-j-start text-left">
                {{ slotProps.item.category }}
              </div>
            </template>
            <template v-slot:items-5="slotProps">
              <div class="display-flex f-align-center f-j-end">
                {{ shortenDigits(slotProps.item.installs) }}
              </div>
            </template>
            <template v-slot:items-6="slotProps">
              <div class="display-flex f-align-center f-j-end">
                {{ shortenDigits(slotProps.item.revenue) }}
              </div>
            </template>
            <template v-slot:emptySlot>
              <div class="mt-50" v-if="activeTab === 'recommended' && !recommendedLoaded">Loading...</div>
              <div class="mt-50" v-else-if="activeTab === 'search' && !searchLoading && searchCompetitorsObj === null">Enter search query and start searching</div>
              <div class="mt-50" v-else-if="activeTab === 'search' && searchLoading">Searching...</div>
              <div class="mt-50 mb-50" v-else>
                <empty-result @reset="this.$refs.filters?.clearAllAndApply()" :filters-reset="this.$refs.filters?.filtersChangedCount > 0"></empty-result>
              </div>
            </template>
          </SimpleInfoTable>
        </div>
        <template v-if="!oneSelectMode">
          <div class="save-and-continue" @click="saveAndContinue" v-if="maxCheckedCount === null || finalObj.getCompetitorsCheckedOnlyInitial().length <= maxCheckedCount">
            <span>Save and continue</span>
          </div>
          <div class="save-and-continue gray" @click="saveAndContinue" v-else>
            <tooltip width="max-content" :allow-point-events="true">
              <template v-slot:slotTrigger>
                <span>Save and continue</span>
              </template>
              <template v-slot:slotContent>
                Max competitors count is {{ maxCheckedCount }}
              </template>
            </tooltip>
          </div>
        </template>
      </div>
    </div>
  </custom-modal>
</template>

<style lang="scss" src="./competitors_select_modal.scss"></style>
