<template>
  <div class="user-header-details"
       v-if="token !== null && loaded"
       v-click-outside="outside"
       :class="{'active': userDetailsIsActive}">
    <div class="user-image"
         @click="headerDropdownClicked">
      <img :src="avatarUrl"
           width="36"
           height="36"
           alt="User image">
    </div>

    <div class="user-dropdown-info">
      <div class="dropdown-item large-margin">
        <strong>{{ userData.name }}</strong>
      </div>
      <div class="dropdown-item">
        <router-link :to="{name: 'Profile'}">
          My Account
        </router-link>
      </div>
      <div class="dropdown-item">
        <router-link :to="{name: 'NotificationsList'}">
          Reports
        </router-link>
      </div>
      <div class="dropdown-item">
        <router-link :to="{name: 'IntegrationSettingsList'}">
          Integrations
        </router-link>
      </div>
      <div class="dropdown-item"
           @click="logoutClicked">
        <button type="button">
          Log Out
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "HeaderUserDetails",
  props: {
    userData: {
      type: Object
    },
  },
  data() {
    return {
      userDetailsIsActive: false,
      env: process.env.VUE_APP_API_URL,
      loaded: false
    }
  },
  methods: {
    headerDropdownClicked() {
      this.userDetailsIsActive = !this.userDetailsIsActive;
    },
    outside() {
      this.userDetailsIsActive = false;
    },
    logoutClicked() {
      this.$emit('userLogoutClicked');
    },
  },
  created() {
    this.loaded = true;
  },
  computed: {
    ...mapGetters([
      'token',
    ]),
    avatarUrl() {
      if (this.userData?.avatar === null || this.userData?.avatar === undefined) {
        return require(`@/assets/images/avatar.png`);
      } else if (this.userData?.avatar?.includes('avatar.png')) {
        return require(`@/assets/images${this.userData.avatar}`);
      } else {
        return this.env + 'storage' + this.userData.avatar;
      }
    }
  }
}
</script>

<style scoped lang="scss" src="./styles.scss"></style>