<template>
  <div class="form-group">
    <div class="option-text"
         :class="{active: !toggleModel}"
         id="opt-one">{{ optionOne }}
    </div>
    <input type="checkbox"
           id="check-toggle"
           class="chkbx-toggle"
           name="custom-checkbox"
           :value="toggleModel">
    <label for="check-toggle"
           :class="{checked: toggleModel}"
           @click="switchToggleChanged"></label>
    <div class="option-text"
         :class="{active: toggleModel}"
         id="opt-two">{{ optionTwo }}
    </div>
  </div>
</template>

<script>
export default {
  name: "SwitchToggle",
  props: {
    optionOne: {
      type: String
    },
    optionTwo: {
      type: String
    },
  },
  data() {
    return {
      toggleModel: 0,
    }
  },
  methods: {
    switchToggleChanged() {
      this.toggleModel = !this.toggleModel;
      this.$emit('toggle-changed', this.toggleModel);
    }
  }
}
</script>

<style scoped lang="scss" src="./styles.scss"></style>