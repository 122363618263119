<template>
  <div class="filters-dropdown"
       v-click-outside="outside"
       :class="[{'active': isActive}, classes]">
    <div class="accordion-inner-content">
      <div class="header-block"
           @click="accordionTrigger">
        <slot name="header">
          <div class="value">
            Filters
          </div>
        </slot>

        <div class="counter"
             v-if="usedFiltersAmount > 0">&nbsp;({{ usedFiltersAmount }})
        </div>

        <div class="icon-wrap"
             :style="{color: color}"
             v-if="caretVisible">
          <svg-icon icon="angle-down-solid"/>
        </div>
      </div>

   
      <div class="content-block"
           ref="contentBlock">

        <svg-icon @click="isActive = false"
                  class="close-icon"
                  icon="close"/>

        <div class="content-items-list">
<!--          <template v-for="filterData in dividedFilters">-->
<!--            <div  class="filter-column" v-if="filterData.length">-->
            <div  class="filters-container" v-if="filtersConfig.length" :class="{'oneColumn': filtersConfig.length <= 3}">
              <div class="content-item" v-for="item in filtersConfig">
                <div>
                  <template v-if="item?.type === 'range'">
                    <range-input
                        @filter-changed="filterChanged($event, item?.filter)"
                        @filter-cleaned="filterCleaned($event, item?.filter)"
                        :min-value="item?.min"
                        :max-value="item?.max"
                        :min-limit="item?.minLimit"
                        :max-limit="item?.maxLimit"
                        :presets-data="item?.presetsData"
                        :reset-data-counter="resetDataCounter"
                        :clear-to-limit="clearToLimit"
                        :key="clearToLimit ? 0 : filterKey"
                        ref="cleanFilter"
                        :initial-data="item">
                      {{ item?.title }}
                    </range-input>
                  </template>
                  <template v-else-if="item?.type === 'radio'">
                    <words-radio-buttons @filter-changed="filterChanged($event, item?.filter)"
                                         @filter-cleaned="filterCleaned($event, item?.filter)"
                                         :initial-value="item?.value"
                                         :reset-data-counter="resetDataCounter"
                                         :key="filterKey"
                                         :initial-data="item">
                      {{ item?.title }}
                    </words-radio-buttons>
                  </template>
                  <template v-else-if="item?.type === 'dropdown'">
                    <sources-component :initial-data="item"
                                       :title="item?.title"
                                       :initial-value="item?.value"
                                       :reset-data-counter="resetDataCounter"
                                       @filter-changed="filterChanged($event, item?.filter)"
                                       :initial-default-value="item?.filter === 'keyword_tracked' ? 'All' : 'All Sources'"
                                       :key="filterKey"
                                       @filter-cleaned="filterCleaned($event, item?.filter, 'clean')">
                      {{ item?.title }}
                    </sources-component>
                  </template>
                  <template v-else-if="item?.type === 'dropdown-multi'">
                    <sources-component :initial-data="item"
                                       :key="item.presetsData?.map((item) => item.filterValue).join('|')"
                                       :multi-select="true"
                                       ref="cleanFilter"
                                       :title="item?.title"
                                       :initial-value="item?.value"
                                       :reset-data-counter="resetDataCounter"
                                       @filter-changed="filterChanged($event, item?.filter)"
                                       :initial-default-value="item?.filter === 'categories' ? 'All categories' : 'All'"
                                       @filter-cleaned="filterCleaned($event, item?.filter, 'clean')">
                      {{ item?.title }}
                    </sources-component>
                  </template>

                </div>
              </div>
            </div>
<!--          </template>-->
        </div>
        <div class="btn-block">
          <base-button mode="outline"
                       height="36px"
                       width="100px"
                       border-radius="4px"
                       @click="clearAll">Clear all
          </base-button>

          <base-button height="36px"
                       width="100px"
                       border-radius="4px"
                       @click="applyFilters">Apply
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import customRangeComponent from "@/components/Forms/CustomRangeComponent/index.vue";
import WordsRadioButtons from "@/components/UI/FiltersComponent/components/WordsRadioButtons/index.vue";
import SourcesDropdown from "@/components/UI/FiltersComponent/components/SourcesDropdown/index.vue";
import SourcesComponent from "@/components/UI/FiltersComponent/components/SourcesComponent/index.vue";

export default defineComponent({
  name: "index",
  components: {
    'range-input': customRangeComponent,
    'words-radio-buttons': WordsRadioButtons,
    'sources-dropdown': SourcesDropdown,
    'sources-component': SourcesComponent,
  },
  data() {
    return {
      isActive: false,
      fullHeight: 0,
      filtersConfig: [],
      filterCollectedData: {},
      usedFiltersAmount: 0,
      resetDataCounter: 0,
      filterKey: 0,
    }
  },
  props: {
    clearToLimit: {
      type: Boolean,
      default: false
    },
    initialHeight: {
      type: String
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#102148'
    },
    justify: {
      type: String,
      default: 'jcfs'
    },
    background: {
      type: String,
      default: '#ffffff'
    },
    caretVisible: {
      type: Boolean,
      default: false
    },
    dataUpdated: {
      type: Number,
      required: false
    },
    classes: {
      type: Array,
      required: false
    },
    filtersData: {
      type: Object,
    },
    resetFilters: {
      type: Number,
    }
  },
  emits: ['dropdown-opened', 'dropdown-closed', 'filters-applied'],
  mounted() {
    this.fullHeight = this.initialHeight;
    let callStack = [];
    this.filtersConfig = this.filtersData.map(item => {
      if (item?.isInitiallyActive) {
        this.usedFiltersAmount++;
        if (item.type === 'range') {
          callStack.push(() => this.filterChanged({
            min: item?.min,
            max: item?.max,
            btnState: true
          }, item?.filter));
        } else if (item.type === 'dropdown-multi') {
          // console.log(item);
        }
      }
      return {
        ...item
      }
    });

    callStack.forEach(item => item());
  },
  methods: {
    accordionTrigger(e) {
      e.stopPropagation();
      if (!e.target.classList.contains('sublist-item')) {
        this.isActive = !this.isActive;
        this.calculateHeight();
      }

      if (this.isActive === false) {
        this.$emit('dropdown-closed');
      }

    },
    calculateHeight() {
      const initHeight = parseInt(this.initialHeight);
      if (this.isActive) {
        this.fullHeight = this.$refs.contentBlock.clientHeight + initHeight + 'px';
      } else {
        this.fullHeight = this.initialHeight;
      }
    },
    filterChanged(e, filterName) {
      let tempFilterData = {...this.filterCollectedData};
      const foundFiltersConfigObj = this.filtersConfig?.find(item => item?.filter === filterName);

      if (filterName !== 'sources' && filterName !== 'keyword_tracked' && filterName !== 'range_words_count' && filterName !== 'categories' && filterName !== 'categories_names') {
        const keyMin = 'filter[' + [filterName] + '][from]';
        const keyMax = 'filter[' + [filterName] + '][to]';
        if (e?.state === 'reset') {
          delete tempFilterData[keyMin];
          delete tempFilterData[keyMax];
        } else if ((e?.btnState === undefined || e?.btnState === false) && foundFiltersConfigObj && e?.min === foundFiltersConfigObj?.min && e?.max === foundFiltersConfigObj?.max) {
          delete tempFilterData[keyMin];
          delete tempFilterData[keyMax];
        } else {
          tempFilterData[keyMin] = e?.min ?? null;
          tempFilterData[keyMax] = e?.max ?? null;
        }
      } else if (filterName === 'range_words_count') {
        const keyMin = 'filter[' + [filterName] + '][from]';
        const keyMax = 'filter[' + [filterName] + '][to]';

        if (!e?.btnState && foundFiltersConfigObj && e?.min === 1 && e?.max === 1000000) {
          delete tempFilterData[keyMin];
          delete tempFilterData[keyMax];
        } else {
          tempFilterData[keyMin] = e?.min ?? null;
          tempFilterData[keyMax] = e?.max ?? null;
        }
      } else if (filterName === 'keyword_tracked') {
        const key = 'filter[keyword_tracked][isTracked]';

        if (e?.filterValue === '' || e?.filterValue === 'all') {
          tempFilterData[key] = null;
        } else if (e.title !== 'All') {
          tempFilterData[key] = e?.filterValue === 'true' ? 'true' : 'false';
        }

      } else if (filterName === 'categories' || filterName === 'categories_names') {
        const keyPrefix = 'filter[categories_names][list]';
        for (const key in tempFilterData) {
          if (key.includes(keyPrefix)) {
            delete tempFilterData[key];
          }
        }

        if (Object.keys(e.e).length > 0) {
          for (const key in e.e) {
            tempFilterData[keyPrefix + '[' + key + ']'] = e.e[key];
          }
        } else {
          tempFilterData[keyPrefix] = '[]';
        }
      } else {
        const key = 'filter[sources][list][0]';
        if (!e?.btnState && foundFiltersConfigObj && e?.filterValue === 'all') {
          delete tempFilterData[key];
        } else {
          tempFilterData[key] = e?.filterValue !== 'all' ? e.filterValue : null;
        }
      }

      this.filterCollectedData = {...tempFilterData};
    },
    filterCleaned(e, filterName) {
      setTimeout(() => {
        let tempFilterData = {...this.filterCollectedData};

        if (filterName !== 'sources' && filterName !== 'keyword_tracked' && filterName !== 'categories') {
          const keyMin = 'filter[' + [filterName] + '][from]';
          const keyMax = 'filter[' + [filterName] + '][to]';
          delete tempFilterData[keyMin];
          delete tempFilterData[keyMax];
        } else if (filterName === 'keyword_tracked') {
          const key = 'filter[keyword_tracked][isTracked]';
          delete tempFilterData[key];
        } else if (filterName === 'categories') {
          const keyPrefix = 'filter[categories_names][list]';
          for (const key in tempFilterData) {
            if (key.includes(keyPrefix)) {
              delete tempFilterData[key];
            }
          }
        } else {
          const key = 'filter[sources][list][0]';
          delete tempFilterData[key];
        }

        this.filterCollectedData = {...tempFilterData};
        this.$emit('filters-applied', this.filterCollectedData);

        if (this.usedFiltersAmount > 0) {
          this.usedFiltersAmount--;
        }
      }, 300);

    },
    applyFilters() {
      this.$emit('filters-applied', this.filterCollectedData);
      if (this.filtersData?.length > 0) {
        const regex = /filter\[(?<filter_name>.*?)\]/;
        let counter = {};
        for (const key in this.filterCollectedData) {
          if (this.filterCollectedData[key]) {
            let filter = regex.exec(key).groups?.filter_name;
            if (filter) {
              counter[filter] = filter;
            }
          }
        }

        this.usedFiltersAmount = Object.keys(counter).length;
      }

      this.isActive = false;
    },
    clearAll() {
      try {
        this.$refs.cleanFilter?.map((item) => item.cleanFilter());
      } catch (e) {
        console.error(e);
      }

      let tempCollection = {...this.filterCollectedData};
      if (this.filtersData?.length > 0) {
        for (const key in this.filterCollectedData) {
          delete tempCollection[key];
        }
      }

      this.filterCollectedData = {...tempCollection};
      this.resetDataCounter++;
      this.usedFiltersAmount = 0;

      this.$emit('filters-applied', this.filterCollectedData);
      this.isActive = false;
      this.filterKey++;
    },
    outside(e) {
      if (e.target?.classList?.contains('clear-btn')) {
        return;
      }

      this.isActive = false;
      this.filterKey++;
    },
  },
  computed: {
    dividedFilters() {
      const firstColumnFilterCount = Math.ceil(this.filtersConfig.length / 2);

      const firstColumnFilterData = this.filtersConfig.slice(0, firstColumnFilterCount)
      const secondColumnFilterData = this.filtersConfig.slice(firstColumnFilterCount, this.filtersConfig.length)
      return [firstColumnFilterData, secondColumnFilterData]
    }
  },
  watch: {
    initialHeight() {
      this.calculateHeight();
    },
    collapsed() {
      this.isActive = false;
      this.calculateHeight();
    },
    dataUpdated(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.isActive = false;
        this.calculateHeight();
      }
    },
    filtersData(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.filtersConfig = [...newVal];
        this.usedFiltersAmount = 0;
        this.filtersConfig = this.filtersData.map(item => {
          if (item?.isInitiallyActive || item?.isInitiallyActive === 0) {
            this.usedFiltersAmount++;
          }
          return {
            ...item
          }
        });
      }
    },
    resetFilters(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.clearAll();
      }
    }
  }
})
</script>

<style lang="scss" src="./styles.scss" scoped></style>