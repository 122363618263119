export default {
    SET_TRACKED_KEYWORDS(state, payload) {
        state.trackedKeywords = payload;
    },
    SET_SUGGESTED_KEYWORDS(state, payload) {
        state.suggestedKeywords = payload;
    },
    SET_SORTING_META(state, payload) {
        state.sortingMeta = {...payload};
    },
    SET_URL_QUERY_STRING(state, payload) {
        state.urlQueryString = payload;
    },
    SET_LIVE_SEARCH_RESULTS(state, payload) {
        state.liveSearchResults = {...payload};
    },
    SET_POPULARITY_CHART_RESULTS(state, payload) {
        state.popularityChartResults = {...payload};
    },
    SET_COMPETITORS_RESULTS(state, payload) {
        state.competitors = {...payload};
    },
    SET_TRACKING_KEYWORDS_COUNT(state) {
        state.trackingKeywordsCount++;
    },
    CURRENT_WORD_SELECTED(state, payload) {
        if (payload === state.currentWordSelected) {
            state.currentWordSelected = payload += ' ';
        } else {
            state.currentWordSelected = payload;
        }
    },
    SET_UNCOVERED_WORDS(state, payload) {
        state.uncoveredWordsState = [...payload];
    },
};