<template>
  <div class="header-burger"
       v-click-outside="outside">
    <div class="header-nav"
         v-if="burgerIsActive"
         :class="{'show': burgerIsActive}">

      <div class="header-mobile-options">
        <header-main-options :current-country="currentCountry"
                             :current-app="currentApp"/>
      </div>

      <nav class="sidebar-links header-accordions">
        <ul class="links-container">
          <template v-for="menuItem in menuList">
            <template v-if="menuItem.submenuList">
              <li class="link sub-menu-container">
                <sidebar-accordion initial-height="auto"
                                   :open-on-init="openOnInitCalculate(menuItem.categoryTitle)"
                                   @user-accordion-action="userAccordionAction($event, menuItem.categoryTitle)"
                                   color="var(--neutral-900)">
                  <template v-slot:header>
                    <div class="link-icon">
                      <svg-icon :icon="menuItem.categoryIcon"/>
                    </div>
                    <div class="link-text"
                         @click="sidebarAccordionClicked(menuItem.categoryTitle)">
                      {{ menuItem.categoryTitle }}
                    </div>
                  </template>
                  <template v-slot:accordion-body>
                    <div class="link-sublist">
                      <template v-for="menuSubitem in menuItem.submenuList">
                        <template v-if="menuSubitem.onlyForBeta !== true || hasBetaCookie()">
                          <router-link :to="menuSubitem.route"
                                       class="sublist-item"
                                       exact>
                              <span class="sublist-item-title" :data-intercom="menuSubitem.title">
                                {{ menuSubitem.title }}
                                <span class="counter">
                                  {{ getCounter(dataCounts, menuSubitem.title) }}
                                </span>
                              </span>
                          </router-link>
                        </template>
                      </template>
                    </div>
                  </template>
                </sidebar-accordion>
              </li>
            </template>
            <template v-else>
              <router-link class="link"
                           tag="li"
                           :to="menuItem.route"
                           exact>
                <div class="link-icon">
                  <svg-icon :icon="menuItem.icon"/>
                </div>
                <div class="link-text">
                  {{ menuItem.title }}
                </div>
              </router-link>
            </template>
          </template>
        </ul>
      </nav>

      <ul class="header-nav-list">
        <li class="user-block-data">
          <div class="user-image">
            <img :src="avatarUrl"
                 width="46"
                 height="46"
                 alt="User image">
          </div>
          <div class="user-info-block">
            <div class="user-name">
              {{ userData.name }}
            </div>
            <div class="user-plan">{{ userSubscription?.plan_name ?? '-' }}</div>
          </div>
        </li>
        <li>
          <router-link :to="{name: 'Profile'}"
                       class="header-link">
            My Account
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'NotificationsList'}"
                       class="header-link">
            Reports
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'IntegrationSettingsList'}"
                       class="header-link">
            Integrations
          </router-link>
        </li>
        <li>
          <button type="button"
                  class="header-link"
                  @click="logoutClicked">Log Out
          </button>
        </li>
        <li class="limits-details">
          <div class="user-limits">
            <div class="user-limits-details">
              <div class="user-limits-item">
                <div class="limit-name">Apps:</div>
                <div class="limit-value">{{ userLimits?.max_apps?.used ?? 0 }} of {{
                    userLimits?.max_apps?.total ?? 0
                  }}
                </div>
              </div>

              <div class="user-limits-item">
                <div class="limit-name">Keywords:</div>
                <div class="limit-value">{{ userLimits?.max_keywords?.used ?? 0 }} of
                  {{ userLimits?.max_keywords?.total ?? 0 }}
                </div>
              </div>

              <div class="user-limits-item">
                <div class="limit-name">Competitors:</div>
                <div class="limit-value">{{ userLimits?.max_competitors?.used ?? 0 }} of
                  {{ userLimits?.max_competitors?.total ?? 0 }}
                </div>
              </div>
            </div>
            <a class="limits-link" :href="env_app + 'pricing'">
              <span>More About Limits</span>
              <svg-icon icon="arrow"/>
            </a>
          </div>
        </li>
      </ul>
    </div>

    <div class="hidden-buttons">
      <button class="show-mobile-menu"
              aria-label="Burger menu"
              type="button"
              @click="headerBurgerClicked">
        <span class="bars-icon"
              :class="{active: burgerIsActive}">
          <span class="bar-line"></span>
        </span>
      </button>
    </div>

    <custom-modal :opened="showFeatureModal"
                  :max-width="540"
                  class-name="request-feature-modal"
                  @closeModal="closeModal('showFeatureModal')">
      <div class="modal-message textarea-block">
        <div class="form-row">
          <div class="form-col w100p">
            <div class="modal-title">
              We would like to hear about the features you need
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-col w100p">
            <div class="form-control">
              <label><textarea name="content"
                               v-model="contactFormData.content"
                               placeholder="Enter your message"></textarea>
              </label>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-col w100p btn-block">
            <base-button height="44px"
                         width="120px"
                         font-size="15px"
                         class="btn btn-default"
                         :class="{'disabled gray': contactFormData.content?.length === 0}"
                         :disabled="contactFormData.content?.length === 0"
                         @click="sendContactForm">Request a Feature
            </base-button>
          </div>
        </div>
      </div>
    </custom-modal>

    <custom-modal :opened="showSuccessModal"
                  :max-width="540"
                  class-name="request-feature-modal"
                  @closeModal="closeModal('showSuccessModal')">
      <div class="modal-message">
        <div class="success-modal-text">
          Thank you for sharing your ideas. Our team will analyze your proposal.
        </div>
      </div>
    </custom-modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {menu} from "@/sidebar-menu/index.js";
import Accordion from '@/components/UI/Accordion/index';
import SidebarMenuItem from '@/components/TheSidebar/SidebarMenuItem/index';
import Label from '@/components/UI/Label/index';
import {getCookie, hasBetaCookie, setCookie} from "@/utils/utils";
import {httpRequest} from "@/api";
import SidebarMenuAccordion from "@/components/UI/SidebarMenuAccordion/index.vue";
import HeaderMainOptions from "@/components/UI/HeaderMainOptions/index.vue";
import {getCounter} from "@/sidebar-menu/counters";

export default {
  name: "HeaderBurger",
  props: {
    userData: {
      type: Object
    }
  },
  emits: ['userLogoutClicked'],
  components: {
    'custom-accordion': Accordion,
    'sidebar-accordion': SidebarMenuAccordion,
    'sidebar-menu-item': SidebarMenuItem,
    'menu-label': Label,
    'header-main-options': HeaderMainOptions,
  },
  data() {
    return {
      burgerIsActive: false,
      accordionIsActive: false,
      env: process.env.VUE_APP_API_URL,
      env_app: process.env.VUE_APP_URL,
      menuList: menu,
      betaTesterCookie: null,
      contactFormData: {
        content: '',
      },
      showFeatureModal: false,
      showSuccessModal: false,
      userSubscription: {},
      recalculateHeightCounter: 0,
      reOpenDropdownCounter: 0,
      accordionActiveOptions: {},
      sidebarRouteMenu: menu,
    }
  },
  async mounted() {
    const accordionCookie = getCookie('sidebarMenuActiveOptions');
    if (accordionCookie) {

      for (const key in this.sidebarRouteMenu) {
        const accordionOptions = JSON.parse(accordionCookie);
        this.accordionActiveOptions[this.sidebarRouteMenu[key]?.categoryTitle] = true;
        this.accordionActiveOptions = {...this.accordionActiveOptions, ...accordionOptions};
      }
    }

    this.betaTesterCookie = getCookie('betaTesterCookie');

    if (window.innerWidth < 768) {
      await this.getUserSubscription();
    }

    if (JSON.stringify(this.dataCounts) === '{}'
     && this.$route.name !== 'KeywordsTracking'
     && this.$route.name !== 'RankingKeywords'
     && this.$route.name !== 'MetadataKeywords'
     && this.$route.name !== 'RecommendedKeywords') {
      await this.fetchDataCounts();
    }
  },
  methods: {
    getCounter,
    hasBetaCookie,
    headerBurgerClicked() {
      this.burgerIsActive = !this.burgerIsActive;
      this.$store.dispatch('MOBILE_HEADER_OPENED', this.burgerIsActive);

      const accordionCookie = getCookie('sidebarMenuActiveOptions');
      if (accordionCookie) {
        for (const key in this.sidebarRouteMenu) {
          const accordionOptions = JSON.parse(accordionCookie);
          this.accordionActiveOptions[this.sidebarRouteMenu[key]?.categoryTitle] = true;
          this.accordionActiveOptions = {...this.accordionActiveOptions, ...accordionOptions};
        }
      }
    },
    outside() {
      this.burgerIsActive = false;
      this.accordionIsActive = false;
    },
    logoutClicked() {
      this.$emit('userLogoutClicked');
    },
    openFeatureModal() {
      this.showFeatureModal = true;
    },
    async sendContactForm() {
      if (this.contactFormData.content?.length === 0) {
        return;
      }

      const formdata = new FormData();
      formdata.append("author_name", this.userData.name);
      formdata.append("author_email", this.userData.email);
      formdata.append("content", this.contactFormData.content);
      formdata.append("from_url", window.location.origin + this.$route?.path);
      formdata.append("type", "request-feature");
      const result = await httpRequest('POST', process.env.VUE_APP_API_URL + this.$ApiUrls.user.CONTACTS, formdata);

      if (!result?.errors && !result?.message) {
        this.showSuccessModal = true;
        this.contactFormData.content = '';
      } else {
        this.showSuccessModal = false;
      }

      this.showFeatureModal = false;
    },
    async getUserSubscription() {
      const subscription = await httpRequest('GET', process.env.VUE_APP_API_URL + 'api/subscription');
      this.userSubscription = {...subscription};
    },
    recalculateHeightCounterHandler() {
      this.recalculateHeightCounter++;
    },
    openOnInitCalculate(listName) {
      return this.accordionActiveOptions[listName] === undefined || this.accordionActiveOptions[listName] === true;
    },
    userAccordionAction(event, data) {
      const accordionCookie = getCookie('sidebarMenuActiveOptions');
      const cookieDomain = process.env.VUE_APP_COOKIE_DOMAIN;
      const days = 30;
      let accordionActiveOptions = {};
      if (accordionCookie) {
        accordionActiveOptions = JSON.parse(accordionCookie);
      }

      accordionActiveOptions[data] = event;

      if (data === 'Collect' && event === false) {
        accordionActiveOptions.Keywords = true;
        accordionActiveOptions.Competitors = true;
        setTimeout(() => {
          this.reOpenDropdownCounter++;
        }, 300);
      }

      setCookie('sidebarMenuActiveOptions', JSON.stringify(accordionActiveOptions), cookieDomain, days);
    },
    subMenuAccordionAction(event, data) {
      this.userAccordionAction(event, data);
      this.recalculateHeightCounterHandler();
    },
    async fetchDataCounts() {
      this.$store.dispatch('SET_DATA_COUNTS_LOADER', false);
      await this.$store.dispatch('SET_DATA_COUNTS');
      this.$store.dispatch('SET_DATA_COUNTS_LOADER', true);
    },
    setStringFormatting(str) {
      if (str && str !== '') {
        return str?.toLocaleString('ru-RU', {maximumFractionDigits: 0});
      }
    },
    sidebarAccordionClicked(title) {
      if (title === 'Keywords') {
        this.$router.push({
          name: 'KeywordsTracking'
        });
      } else if (title === 'Competitors') {
        this.$router.push({
          name: 'Competitors'
        });
      } else if (title === 'Applications') {
        this.$router.push({
          name: 'AnalyzeApp'
        });
      }

      this.userAccordionAction(true, title);
    },
  },
  computed: {
    ...mapGetters([
      'sidebarIsCollapsed',
      'userLimits',
      'currentApp',
      'mobileHeaderOpened',
      'dataCounts',
      'currentCountry',
    ]),
    avatarUrl() {
      if (this.userData?.avatar === null || this.userData?.avatar === undefined) {
        return require(`@/assets/images/avatar.png`);
      } else if (this.userData?.avatar?.includes('avatar.png')) {
        return require(`@/assets/images${this.userData.avatar}`);
      } else {
        return this.env + 'storage' + this.userData.avatar;
      }
    },
    currentAppId() {
      return getCookie('currentAppId');
    },
  },
}
</script>

<style scoped lang="scss" src="./styles.scss"></style>