<template>
  <div class="order-radaso-modal-full">
    <svg-icon class="close-modal-icon" icon="close" @click="closeModal"/>

    <div class="left-side">
      <h2>ASO Services with our trusted partner</h2>
      <div>
        An authorized Agency with a robust history
        of delivering <span class="bold">excellent results in over 1000 projects</span> across:
        <div>
          <svg-icon icon="green-checked-circle"/>
          various niches,
        </div>
        <div>
          <svg-icon icon="green-checked-circle"/>
          creative techniques
        </div>
        <div>
          <svg-icon icon="green-checked-circle"/>
          innovative strategies
        </div>
        <div class="bold">will help you boost your app installs and revenue!</div>
        <div class="mb-24">🚀</div>
        <div>
          Highly qualified professionals with years of experience
          in the ASO will bring top-notch skills
          to make your project more successful
        </div>
        <div class="bottom-image">
          <img src="@/assets/images/radaso-modal.png">
          <div class="text-wrap">
            <div class="text">
              <span>👉</span> The pricing model is results-focused
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="right-side">
      <h3>Leave Your Order</h3>
      <div class="app-input">
        <label>App</label>
        <live-search-with-presets :selected-app="selectedApp"
                                  v-if="selectedCountry.code"
                                  :show-select-from-competitors="false"
                                  :current-country="selectedCountry"
                                  :presets="myApps"
                                  :countries="countries"
                                  :is-countries-loading="isCountriesLoading"
                                  store="both"
                                  placeholder="Type to find any app from stores..."
                                  :show-current="true"
                                  @changed="changeApp"
        />
      </div>
      <div>
        <label>I’m interested in:</label>
        <div class="interested-wrap">
          <div class="interested-block">
            <div class="interested-point">
              <div class="label">
                <div class="checkboxes-block type-2">
                  <input v-model="interestedIn['keywords-research']" type="checkbox" id="keywords-research">
                  <label for="keywords-research">Keywords Research <span>/ 5 days</span></label>
                </div>
                <div class="arrow"
                     @click="displayDescription['keywords-research'] = !displayDescription['keywords-research']">
                  <svg-icon icon="angle-down-solid" :class="{opened: displayDescription['keywords-research']}"/>
                </div>
              </div>
              <div class="description" v-show="displayDescription['keywords-research']">
                <ul>
                  <li>competitor selection to analyze semantic core</li>
                  <li>semantic core creation for agreed languages</li>
                </ul>
              </div>
            </div>
            <div class="interested-point">
              <div class="label">
                <div class="checkboxes-block type-2">
                  <input v-model="interestedIn['aso-audit']" type="checkbox" id="aso-audit">
                  <label for="aso-audit">ASO Audit <span>/ 15 days</span></label>
                </div>
                <div class="arrow"
                     @click="displayDescription['aso-audit'] = !displayDescription['aso-audit']">
                  <svg-icon icon="angle-down-solid" :class="{opened: displayDescription['aso-audit']}"/>
                </div>
              </div>
              <div class="description" v-show="displayDescription['aso-audit']">
                <ul>
                  <li>comprehensive competitors and market research,</li>
                  <li>identifying growth opportunities,</li>
                  <li>creating hypotheses to optimize conversion rates</li>
                </ul>
              </div>
            </div>
            <div class="interested-point">
              <div class="label">
                <div class="checkboxes-block type-2">
                  <input v-model="interestedIn['visual-aso']" type="checkbox" id="visual-aso">
                  <label for="visual-aso">Visual ASO <span>/ 4 weeks</span></label>
                </div>
                <div class="arrow"
                     @click="displayDescription['visual-aso'] = !displayDescription['visual-aso']">
                  <svg-icon icon="angle-down-solid" :class="{opened: displayDescription['visual-aso']}"/>
                </div>
              </div>
              <div class="description" v-show="displayDescription['visual-aso']">
                <ul>
                  <li>researching,  hypotheses & prioritization,</li>
                  <li>a brief for a designer,</li>
                  <li>ready assets (2 screenshot sets and icon)</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="interested-block">
            <div class="interested-point">
              <div class="label">
                <div class="checkboxes-block type-2">
                  <input v-model="interestedIn['metadata-optimization']" type="checkbox" id="metadata-optimization">
                  <label for="metadata-optimization">Metadata Optimization <span>/ 10 days</span></label>
                </div>
                <div class="arrow"
                     @click="displayDescription['metadata-optimization'] = !displayDescription['metadata-optimization']">
                  <svg-icon icon="angle-down-solid" :class="{opened: displayDescription['metadata-optimization']}"/>
                </div>
              </div>
              <div class="description" v-show="displayDescription['metadata-optimization']">
                <ul>
                  <li>competitors research,</li>
                  <li>competitors research</li>
                </ul>
              </div>
            </div>
            <div class="interested-point">
              <div class="label">
                <div class="checkboxes-block type-2">
                  <input v-model="interestedIn['launch-support']" type="checkbox" id="launch-support">
                  <label for="launch-support">Launch Support <span>/ 30 days</span></label>
                </div>
                <div class="arrow"
                     @click="displayDescription['launch-support'] = !displayDescription['launch-support']">
                  <svg-icon icon="angle-down-solid" :class="{opened: displayDescription['launch-support']}"/>
                </div>
              </div>
              <div class="description" v-show="displayDescription['launch-support']">
                <ul>
                  <li>comprehensive competitors and market research,</li>
                  <li>keywords research, creating sets of metadata</li>
                </ul>
              </div>
            </div>
            <div class="interested-point">
              <div class="label">
                <div class="checkboxes-block type-2">
                  <input v-model="interestedIn['full-aso-support']" type="checkbox" id="full-aso-support">
                  <label for="full-aso-support">Full ASO Support <span>/ 30 days</span></label>
                </div>
                <div class="arrow"
                     @click="displayDescription['full-aso-support'] = !displayDescription['full-aso-support']">
                  <svg-icon icon="angle-down-solid" :class="{opened: displayDescription['full-aso-support']}"/>
                </div>
              </div>
              <div class="description" v-show="displayDescription['full-aso-support']">
                Based on app objectives. Request an individual offer
              </div>
            </div>
            <div class="interested-point">
              <div class="label">
                <div class="checkboxes-block type-2">
                  <input v-model="interestedIn['user-acquisition']" type="checkbox" id="user-acquisition">
                  <label for="user-acquisition">User Acquisition <span>/ 30 days</span></label>
                </div>
                <div class="arrow"
                     @click="displayDescription['user-acquisition'] = !displayDescription['user-acquisition']">
                  <svg-icon icon="angle-down-solid" :class="{opened: displayDescription['user-acquisition']}"/>
                </div>
              </div>
              <div class="description" v-show="displayDescription['user-acquisition']">
                Google UAC (Universal App Campaigns), Apple Search Ads (ASA), Meta Ads, TikTok Ads
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="inputs-group">
        <div class="input-wrap">
          <label for="name">
            First Name
            <span class="asterisk">*</span>
          </label>
          <input v-model="requestFormData['name']" name="name" id="name">
          <div class="error" v-if="errors.name.length">
            {{ errors.name }}
          </div>
        </div>
        <div class="input-wrap">
          <label for="lastname">
            Last Name
            <span class="asterisk">*</span>
          </label>
          <input v-model="requestFormData['lastname']" name="lastname" id="lastname">
          <div class="error" v-if="errors.lastname.length">
            {{ errors.lastname }}
          </div>
        </div>
      </div>
      <div class="radio-buttons">
        <label>I prefer to contact by:</label>
        <radio-button @click="contactWay = 'email'" :active="contactWay === 'email'">
          Email
        </radio-button>
        <radio-button @click="contactWay = 'phone'" :active="contactWay === 'phone'">
          Phone
        </radio-button>
        <radio-button @click="contactWay = 'whatsapp'" :active="contactWay === 'whatsapp'">
          WhatsApp
        </radio-button>
      </div>
      <div class="inputs-group">
        <div class="input-wrap">
          <label for="email">
            Email
            <span class="asterisk">*</span>
          </label>
          <input v-model="requestFormData['email']" name="email" id="email">
          <div class="error" v-if="errors.email.length">
            {{ errors.email }}
          </div>
        </div>
        <div class="input-wrap">
          <label for="phone">Phone</label>
          <input v-model="requestFormData['phone']" name="phone" id="phone">
        </div>
      </div>
      <div class="textarea-wrap">
        <label>Please give us more information about your request (optionally)</label>
        <textarea v-model="requestFormData['comment']"></textarea>
      </div>
      <div class="footer">
        <base-button height="40px"
                     width="150px"
                     border-radius="4px"
                     @click="closeModal"
                     mode="outline">
          <span>Cancel</span>
        </base-button>

        <base-button height="40px"
                     width="210px"
                     @click="sendRequestForm"
                     border-radius="4px">
          <span>Contact me</span>
        </base-button>
      </div>
    </div>
  </div>
</template>
<script>
import TextAreaBlock from "@/components/Forms/TextAreaBlock/index.vue";
import RadioButton from "@/components/UI/RadioButton/index.vue";
import TableCheckbox from "@/components/UI/TableCheckbox/index.vue";
import LiveSearchWithPresets from "@/components/Dropdowns/LiveSearchWithPresets/index.vue";
import myApps from "@/api/modules/my-apps";
import {httpRequest} from "@/api";
import {mapGetters} from "vuex";

export default {
  name: 'OrderRadasoModal',
  components: {
    LiveSearchWithPresets,
    TableCheckbox,
    RadioButton,
    TextAreaBlock
  },
  emits: [
    'close-modal',
    'email-sent',
  ],
  props: {
    preSelected: Array,
  },
  data() {
    return {
      displayDescription: {
        'keywords-research': true,
        'aso-audit': false,
        'visual-aso': false,
        'metadata-optimization': false,
        'launch-support': false,
        'full-aso-support': false,
        'user-acquisition': false,
      },
      interestedIn: {
        'keywords-research': false,
        'aso-audit': false,
        'visual-aso': false,
        'metadata-optimization': false,
        'launch-support': false,
        'full-aso-support': false,
        'user-acquisition': false,
      },
      selectedCountry: {},
      selectedApp: {},
      countries: [],
      isCountriesLoading: true,
      myApps: [],
      contactWay: 'email',
      requestFormData: {
        name: '',
        lastname: '',
        email: '',
        phone: '',
        comment: '',
      },
      errors: {
        name: '',
        lastname: '',
        email: ''
      },
    };
  },
  created() {
    this.preSelected.forEach((item) => {
      this.interestedIn[item] = true;
    });
  },
  mounted() {
    this.selectedCountry = {
      code: this.currentCountry?.code,
      name: this.currentCountry?.name,
      id: this.currentCountry?.id,
    };
    this.selectedApp = {
      id: this.currentApp?.id,
      info: {
        logo: this.currentApp?.logo,
        title: this.currentApp?.title,
        store_link: this.currentApp?.store_link,
      },
      store: this.currentApp?.store,
      title: this.currentApp?.title,
      logo: this.currentApp?.logo,
    }

    this.fetchCountries();
    this.fetchMyApps();
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    validate() {
      this.errors = {
        name: '',
        lastname: '',
        email: ''
      };
      if (!this.validateEmail(this.requestFormData.email)) {
        this.errors.email = 'Incorrect email address.';
      }
      if (this.requestFormData.email.length === 0) {
        this.errors.email = 'Email is required.';
      }
      if (this.requestFormData.name.length === 0) {
        this.errors.name = 'Name is required.';
      }
      if (this.requestFormData.lastname.length === 0) {
        this.errors.lastname = 'Lastname is required.';
      }

      return Object.values(this.errors).filter((x) => x.length > 0).length === 0;
    },
    validateEmail(email) {
      if (email.includes(' ')) {
        return false;
      } else {
        let parts = email.split('@');
        if (parts.length !== 2 || parts[1].length < 3 || parts[1].split('.').length < 2) {
          return false;
        }
      }

      return true;
    },
    async sendRequestForm() {
      if (!this.validate()) {
        return;
      }

      const formData = new FormData();
      let interested = [];
      Object.keys(this.interestedIn).forEach((key) => {
        if (this.interestedIn[key]) {
          interested.push(key);
        }
      });
      let content = "Order ASO Services: " + interested.join(', ')
        + ' from ' + this.requestFormData.name + ' ' + this.requestFormData.lastname + '. '
        + 'Email: ' + this.requestFormData.email + ', phone: ' + this.requestFormData.phone + '. '
        + 'I prefer to contact by: ' + this.contactWay + '. '
        + 'App: ' + this.selectedApp.title + '(ID: ' + this.selectedApp.id + ')'
        + ', country: ' + this.selectedCountry.name;
      if (this.requestFormData.comment) {
        content += " Comment: " + this.requestFormData.comment;
      }
      formData.append("author_name", this.requestFormData.name);
      formData.append("author_email", this.requestFormData.email);
      formData.append("content", content);
      formData.append("from_url", window.location.origin + this.$route?.path);
      formData.append("type", "order-aso-services");
      const result = await httpRequest(
        'POST',
        process.env.VUE_APP_API_URL + this.$ApiUrls.user.CONTACTS,
        formData
      );

      if (!result?.errors && !result?.message) {
        this.alertText = "You made a request for ASO Services. We will contact you ";
      }

      this.closeModal();
      this.$emit('email-sent');
    },
    changeApp(app, country) {
      this.selectedApp = app;
      this.selectedCountry = country;
    },
    async fetchCountries() {
      let result = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.keywordsTracking.SEARCH_KEYWORDS_COUNTRIES
          + '/' + this.currentApp.id + '/countries'
      );
      let countries = [];
      result.countries.forEach((country) => {
        countries.push({
          name: country.name,
          code: country.code,
          id: country.id,
        })
      });
      this.countries = countries;
      this.isCountriesLoading = false;
    },
    async fetchMyApps() {
      this.myApps = await myApps.getAllMyApps();
    },
  },
  computed: {
    ...mapGetters([
      'currentCountry',
      'currentApp',
    ]),
  },
}
</script>
<style lang="scss" src="./styles.scss"></style>
