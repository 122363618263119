import {httpRequest} from "@/api";
import {apiUrls} from '@/api/api';
import {deleteCookie, getCookie, setCookie} from "@/utils/utils";
import {UserAppsArrayCreator} from "@/utils/factories";

export default {
    SET_TOKEN({commit}, payload) {
        commit('SET_TOKEN', payload);
    },
    SET_USER_DATA({commit}, payload) {
        commit('SET_USER_DATA', payload);
    },
    SET_USER_AVATAR({commit}, payload) {
        commit('SET_USER_AVATAR', payload);
    },
    TRIGGER_SIDEBAR({commit}, payload) {
        commit('TRIGGER_SIDEBAR', payload);
    },
    SET_IS_MOBILE({commit}, payload) {
        commit('SET_IS_MOBILE', payload);
    },
    SET_IS_TABLET({commit}, payload) {
        commit('SET_IS_TABLET', payload);
    },
    INIT_LAYOUT_LOADED({commit}, payload) {
        commit('INIT_LAYOUT_LOADED', payload);
    },
    async SET_CURRENT_APP({dispatch, commit, state}, payload) {
        const currentAppId = getCookie('currentAppId');
        let appId = payload?.id;
        if (!currentAppId && !payload?.id) {
            deleteCookie('currentAppId');
            return;
        } else if (currentAppId && !payload?.id) {
            appId = currentAppId;
        }

        if (JSON.stringify(state?.currentApp) !== '{}') {
            dispatch('INIT_LATEST_COUNTRY', appId);
        }

        commit('SET_CURRENT_APP', payload);
    },
    SET_CURRENT_COUNTRY({commit}, payload) {
        commit('SET_CURRENT_COUNTRY', payload);
    },
    SET_CURRENT_COUNTRY_MANUAL({commit}, payload) {
        commit('SET_CURRENT_COUNTRY_MANUAL', payload);
    },
    async INIT_SELECTED_APP({dispatch}) {
        let result = await httpRequest('GET', process.env.VUE_APP_API_URL + apiUrls.myApps.MY_APPLICATIONS_NEW);
        result = UserAppsArrayCreator(result) ?? [];
        dispatch('SET_MY_APPS', result);
        const firstAppInList = result[0];
        if (firstAppInList) {
            dispatch('SET_CURRENT_APP_ID', firstAppInList?.id);
            dispatch('SET_CURRENT_APP', firstAppInList);
            setCookie('currentAppId', firstAppInList?.id, null, 7);
        }
    },
    async INIT_LATEST_COUNTRY({commit}, payload) {
        const result = await httpRequest(
          'GET',
          process.env.VUE_APP_API_URL + 'api/profiles/applications/' + payload + '/countries/latest'
        );
        let country;
        if (result?.country) {
            country = {...result?.country};
        } else {
            country = {
                code: "US",
                id: 103,
                name: "United States",
            };
        }

        commit('SET_CURRENT_COUNTRY', country);
    },
    SET_COUNTRY_LOADING({commit}, payload) {
        commit('SET_COUNTRY_LOADING', payload);
    },
    SET_COUNTRY_LIST({commit}, payload) {
        commit('SET_COUNTRY_LIST', payload ?? null);
    },
    SET_USER_LIMITS_ERRORS({commit}) {
        commit('SET_USER_LIMITS_ERRORS');
    },
    INVOKE_USER_LIMITS_MANUAL({commit}) {
        commit('INVOKE_USER_LIMITS_MANUAL');
    },
    SET_USER_CANCEL_TOKEN_SOURCE({commit}, payload) {
        commit('SET_USER_CANCEL_TOKEN_SOURCE', payload);
    },
    SET_USER_LIMITS({commit}, payload) {
        if (payload?.length === 0 || !payload?.max_apps?.total || !payload?.max_competitors?.total || !payload?.max_keywords?.total) {
            commit('SET_USER_LIMITS', null);
            return;
        }

        const limitsObject = {
            ...payload,
            max_apps: {
                total: payload?.max_apps?.total ?? 0,
                used: payload?.max_apps?.used ?? 0,
            },
            max_competitors: {
                total: payload?.max_competitors?.total ?? 0,
                used: payload?.max_competitors?.used ?? 0,
            },
            max_keywords: {
                total: payload?.max_keywords?.total ?? 0,
                used: payload?.max_keywords?.used ?? 0,
            },
        };

        commit('SET_USER_LIMITS', limitsObject);
    },
    UPDATE_USER_LIMITS({commit}) {
        commit('UPDATE_USER_LIMITS');
    },
    SET_PLANS_LIMITS({commit}, payload) {
        const plansConst = {
            FREE: 'Free',
            TEAM_SUPPORTER: 'Team Supporter',
            STRONG_TEAM_SUPPORTER: 'Strong Team Supporter',
        }

        if (payload?.length === 0 || !payload?.[plansConst['FREE']]?.max_apps?.total
            || !payload?.[plansConst['TEAM_SUPPORTER']]?.max_apps?.total
            || !payload?.[plansConst['STRONG_TEAM_SUPPORTER']]?.max_apps?.total) {
            commit('SET_PLANS_LIMITS', null);
            return;
        }

        const limitsObject = {
            free: {
                max_apps: payload?.[plansConst['FREE']]?.max_apps?.total ?? 0,
                max_keywords: payload?.[plansConst['FREE']]?.max_keywords?.total ?? 0,
                max_competitors: payload?.[plansConst['FREE']]?.max_competitors?.total ?? 0,
            },
            team_supporter: {
                max_apps: payload?.[plansConst['TEAM_SUPPORTER']]?.max_apps?.total ?? 0,
                max_keywords: payload?.[plansConst['TEAM_SUPPORTER']]?.max_keywords?.total ?? 0,
                max_competitors: payload?.[plansConst['TEAM_SUPPORTER']]?.max_competitors?.total ?? 0,
            },
            strong_team_supporter: {
                max_apps: payload?.[plansConst['STRONG_TEAM_SUPPORTER']]?.max_apps?.total ?? 0,
                max_keywords: payload?.[plansConst['STRONG_TEAM_SUPPORTER']]?.max_keywords?.total ?? 0,
                max_competitors: payload?.[plansConst['STRONG_TEAM_SUPPORTER']]?.max_competitors?.total ?? 0,
            }
        };

        commit('SET_PLANS_LIMITS', limitsObject);
    },
    SET_CURRENT_APP_ID({commit}, payload) {
        commit('SET_CURRENT_APP_ID', payload);
    },
    SET_MY_APPS({commit}, payload) {
        commit('SET_MY_APPS', payload);
    },
    SET_RECENT_APPS({commit}, payload) {
        let appsArray = [];
        const daysToBeNew = 7;

        for (const key in payload) {
            appsArray.push(payload[key]);
        }

        appsArray = appsArray.sort((a, b) => new Date(b?.added_at)?.getTime() - new Date(a?.added_at)?.getTime());
        appsArray = appsArray.map(item => {
            const today = new Date();
            const itemDate = new Date(item?.added_at);
            const differenceInTime = today.getTime() - itemDate.getTime();
            const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

            return {
                ...item,
                isNew: differenceInDays <= daysToBeNew
            }
        });

        const newAppsFiltered = appsArray.filter(item => item.isNew);

        let numberOfSplice = 3;
        if (newAppsFiltered?.length < 3) {
            numberOfSplice = newAppsFiltered.length;
        }

        let newAppsArray = appsArray.splice(0, numberOfSplice);
        let sortedByRecentUseApps = [...appsArray];
        sortedByRecentUseApps = sortedByRecentUseApps.sort((a, b) => new Date(b?.recently_used_at)?.getTime() - new Date(a?.recently_used_at)?.getTime());
        sortedByRecentUseApps = sortedByRecentUseApps.map(item => {
            return {
                ...item,
                isNew: false
            }
        });
        const recentAppsArray = [...newAppsArray, ...sortedByRecentUseApps];

        commit('SET_RECENT_APPS', recentAppsArray);
    },
    SET_CURRENT_APP_RELOADED({commit}, payload) {
        commit('SET_CURRENT_APP_RELOADED', payload);
    },
    SET_PAGINATION_META({commit}, payload) {
        const metaObject = {
            page: payload?.current_page ?? 1,
            current_page: payload?.current_page ?? 1,
            last_page: payload?.last_page ?? 1,
            from: payload?.from ?? 1,
            to: payload?.to ?? 1,
            total: payload?.total ?? 1,
            per_page: payload?.per_page ?? 50,
            links: payload?.links?.length > 0 ? payload.links : [],
        };
        commit('SET_PAGINATION_META', metaObject);
    },
    async FETCH_PROMISE_ALL_SETTLED({state, commit}, payload) {
        //If browser doesn't support allSettled
        if (!Promise.allSettled) {
            Promise.allSettled = function (promises) {
                return Promise.all(promises.map(p => Promise.resolve(p).then(value => ({
                    status: 'fulfilled',
                    value: value
                }), error => ({
                    status: 'rejected',
                    reason: error
                }))));
            };
        }

        const results = await Promise.allSettled(payload.map(item => {
            return httpRequest(item.method ? item.method : 'POST', item.url, item.data);
        }));

        let tempArr = [];

        //Get fulfilled responses
        const fulfilled = results
            .filter(item => item.status === 'fulfilled')
            .map(item => {
                tempArr.push(item);
                return item;
            });

        //Get rejected errors to display
        const errors = results
            .filter(p => p.status === 'rejected')
            .map(p => p.reason);

        if (errors.length === 0) {
            commit('FETCH_PROMISE_ALL_SETTLED', {success: true, data: tempArr});
        } else {
            commit('FETCH_PROMISE_ALL_SETTLED', {success: false, data: null});
        }
    },
    async SET_DATA_COUNTS({state, commit}, payload) {
        const applicationId = state?.currentApp?.id ?? getCookie('currentAppId');
        if (state.currentCountry?.code && applicationId) {
            let appId = payload?.id ?? applicationId;
            let countryCode = payload?.countryCode ?? state?.currentCountry?.code;
            const url = '?country_code=' + countryCode + '&app_id=' + appId;

            if (!appId || appId === 'undefined') {
                return;
            }

            const result = await httpRequest('GET', process.env.VUE_APP_API_URL + apiUrls.recommendedKeywords.DATA_COUNTS + url);

            const competitorsUrl = '?app_id=' + appId + '&is_competitor=1';
            const selectedCompetitors = await httpRequest('GET', process.env.VUE_APP_API_URL + apiUrls.user.FETCH_COMPETITORS_COUNT + competitorsUrl);
 
            const trackingResultTotal = result?.trackingKeywords?.total ? {...result?.trackingKeywords?.total} : null;
            // const trackingCurrentCountryValue = result?.trackingKeywords && result?.trackingKeywords[state?.currentCountry?.code]
            const trackingCurrentCountryValue = result?.trackingKeywords && result?.trackingKeywords[countryCode]
                ? {...result?.trackingKeywords[countryCode]}
                : null;

            // const currValue = trackingResultTotal || trackingCurrentCountryValue;
            // const trackingResult = currValue ?? null;

            const trackingResult = trackingCurrentCountryValue ?? null;

            const currentTrackingCountryData = {
                "value": trackingResult?.value ?? 0,
                "dynamic": trackingResult?.dynamic !== 0 ? trackingResult?.dynamic : '',
                "dynamicCssClass": trackingResult?.dynamic > 0 ? 'green' : (trackingResult?.dynamic < 0 ? 'red' : ''),
            };

            commit('SET_DATA_COUNTS', {
                rankingKeywords: {
                    "all": {
                        "value": result?.rankingKeywords?.all?.value ?? '',
                        "dynamic": result?.rankingKeywords?.all?.dynamic !== 0 ? result?.rankingKeywords?.all?.dynamic : '',
                        "dynamicCssClass": result?.rankingKeywords?.all?.dynamic > 0 ? 'green' : (result?.rankingKeywords?.all?.dynamic < 0 ? 'red' : ''),
                    },
                    "increased": {
                        "value": result?.rankingKeywords?.increased?.value ?? 0,
                        "dynamic": result?.rankingKeywords?.increased?.dynamic !== 0 ? result?.rankingKeywords?.increased?.dynamic : '',
                        "dynamicCssClass": result?.rankingKeywords?.increased?.dynamic > 0 ? 'green' : (result?.rankingKeywords?.increased?.dynamic < 0 ? 'red' : ''),
                    },
                    "decreased": {
                        "value": result?.rankingKeywords?.decreased?.value ?? 0,
                        "dynamic": result?.rankingKeywords?.decreased?.dynamic !== 0 ? result?.rankingKeywords?.decreased?.dynamic : '',
                        "dynamicCssClass": result?.rankingKeywords?.decreased?.dynamic > 0 ? 'green' : (result?.rankingKeywords?.decreased?.dynamic < 0 ? 'red' : ''),
                    },
                    "new": {
                        "value": result?.rankingKeywords?.new?.value ?? 0,
                        "dynamic": result?.rankingKeywords?.new?.dynamic !== 0 ? result?.rankingKeywords?.new?.dynamic : '',
                        "dynamicCssClass": result?.rankingKeywords?.new?.dynamic > 0 ? 'green' : (result?.rankingKeywords?.new?.dynamic < 0 ? 'red' : ''),
                    },
                    "out": {
                        "value": result?.rankingKeywords?.out?.value ?? 0,
                        "dynamic": result?.rankingKeywords?.out?.dynamic !== 0 ? result?.rankingKeywords?.out?.dynamic : '',
                        "dynamicCssClass": result?.rankingKeywords?.out?.dynamic > 0 ? 'green' : (result?.rankingKeywords?.out?.dynamic < 0 ? 'red' : ''),
                    }
                },
                recommendedKeywords: {
                    "all": {
                        "value": result?.recommendedKeywords?.all?.value ?? '',
                        "dynamic": result?.recommendedKeywords?.all?.dynamic !== 0 ? result?.recommendedKeywords?.all?.dynamic : '',
                        "dynamicCssClass": result?.recommendedKeywords?.all?.dynamic > 0 ? 'green' : (result?.recommendedKeywords?.all?.dynamic < 0 ? 'red' : ''),
                    },
                    "pending": {
                        "value": result?.recommendedKeywords?.pending?.value ?? '',
                        "dynamic": result?.recommendedKeywords?.pending?.dynamic !== 0 ? result?.recommendedKeywords?.pending?.dynamic : '',
                        "dynamicCssClass": result?.recommendedKeywords?.pending?.dynamic > 0 ? 'green' : (result?.recommendedKeywords?.pending?.dynamic < 0 ? 'red' : ''),
                    },
                    "declined": {
                        "value": result?.recommendedKeywords?.declined?.value ?? '',
                        "dynamic": result?.recommendedKeywords?.declined?.dynamic !== 0 ? result?.recommendedKeywords?.declined?.dynamic : '',
                        "dynamicCssClass": result?.recommendedKeywords?.declined?.dynamic > 0 ? 'green' : (result?.recommendedKeywords?.declined?.dynamic < 0 ? 'red' : ''),
                    }
                },
                metaKeywords: {
                    "total": {
                        "value": result?.metaKeywords?.total?.value ?? '',
                        "dynamic": result?.metaKeywords?.total?.dynamic !== 0 ? result?.metaKeywords?.total?.dynamic : '',
                        "dynamicCssClass": result?.metaKeywords?.total?.dynamic > 0 ? 'green' : (result?.metaKeywords?.total?.dynamic < 0 ? 'red' : ''),
                    }
                },
                trackingKeywords: {
                    "total": {...currentTrackingCountryData},
                },
                competitors: {
                    "selectedCount": selectedCompetitors?.count ?? 0
                },
            });
        }
    },
    async SET_DATA_COUNTS_LOADER({state, commit}, payload) {
        commit('SET_DATA_COUNTS_LOADER', payload);
    },
    async SET_COUNTRIES_LIST({state, commit}, payload) {
        commit('SET_COUNTRIES_LIST', payload);
    },
    GLOBAL_LOAD_FINISHED({commit}, payload) {
        commit('GLOBAL_LOAD_FINISHED', payload);
    },
    SET_USER_SUBSCRIPTION({state, commit}, payload) {
        commit('SET_USER_SUBSCRIPTION', payload);
    },
    MOBILE_HEADER_OPENED({state, commit}, payload) {
        commit('MOBILE_HEADER_OPENED', payload);
    },
    SHOW_OVERHEAD_MODAL({commit}) {
        commit('SHOW_OVERHEAD_MODAL');
    },
    SET_ABSOLUTE_DROPDOWN_ID({commit}, payload) {
        commit('SET_ABSOLUTE_DROPDOWN_ID', payload);
    },
    SET_EDITABLE_META({commit}, payload) {
        commit('SET_EDITABLE_META', payload);
    },
    INVOKE_CALL_TO_APP_ADD_ACTION({commit}) {
        commit('INVOKE_CALL_TO_APP_ADD_ACTION');
    },

};