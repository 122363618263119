<script>
import BasicSearchInput from '@/components/UI/BasicSearchInput/index';
import FiltersComponent from "@/components/UI/FiltersComponent/index.vue";
import CustomDropdown from "@/components/UI/CustomDropdown/index.vue";
import TranslateTool from "@/components/DataTables/Tools/TranslateTool/index.vue";
import {mapGetters} from "vuex";
import NoDataFoundBlock from "@/components/DataTables/NoDataFoundBlock/index.vue";
import ProgressIndicator from "@/components/UI/ProgressIndicator/index.vue";
import Pagination from "@/components/Pagination/index.vue";
import CustomDataTableSelect from "@/components/Forms/CustomDataTableSelect/index.vue";
import {SortingData, PaginationData, SearchFilterData} from "@/api/modules/tables/search-filter-data";
import {SimpleDataProvider} from "@/api/modules/tables/simple-data-provider";
import TableCheckbox from "../UI/TableCheckbox/index.vue";
import FiltersNew from "@/components/UI/FiltersComponent/FiltersNew.vue";
import SvgIcon from "../UI/SvgIcon/index.vue";
import DatepickerWithRange from "../UI/DatepickerWithRange/index.vue";
import {ref} from "vue";

export default {
  name: "SimpleInfoTable",
  components: {
    DatepickerWithRange,
    SvgIcon,
    FiltersNew,
    CustomDataTableSelect,
    Pagination,
    ProgressIndicator,
    NoDataFoundBlock,
    TranslateTool,
    CustomDropdown,
    FiltersComponent,
    BasicSearchInput,
    TableCheckbox,
  },
  props: {
    fakePaginationTotal: {
      type: Number,
      default: null
    },
    tableContainerStyles: {
      type: Object,
      default: () => ({})
    },
    tableWrapStyles: {
      type: Object,
      default: () => ({})
    },
    tableWrapClasses: {
      type: Object,
      default: () => ({})
    },
    stickyHeader: {
      type: Boolean,
      default: false
    },
    stickyColumns: {
      type: Array,
      default: () => []
    },
    dataProvider: {
      type: Object,
      default: null
    },
    showPagination: {
      type: Boolean,
      default: true
    },
    perPage: {
      type: Number,
      default: 50
    },
    items: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Object,
      default: () => ({})
    },
    defaultSortIndex: {
      type: [Number, String],
      default: '0'
    },
    defaultSortDirection: {
      type: String,
      default: 'asc'
    },
    trClassCallback: {
      type: Function,
      default: () => {}
    },
    trClickCallback: {
      type: Function,
      default: () => {}
    },
    filterConfigs: {
      type: Array,
      default: []
    },
    datePickerConfig: {
      type: Object,
      default: () => null
    },
    downloadUrl: {
      type: String,
      default: null
    },
    searchBy: {
      type: Array,
      default: [],
    },
    multiselect: {
      type: Boolean,
      default: false
    },
    checkedProp: {
      type: String,
      default: 'checked'
    },
    disabledProp: {
      type: String,
      default: 'disabled'
    },
    fullWidthSection: {
      type: Boolean,
      default: false
    },
    showEmptySlot: {
      type: Boolean,
      default: true
    },
    classes: {
      type: Array,
      default: []
    },
    showTotals: {
      type: String, // "top" or "bottom"
      default: null
    },
    rowsPerPageList: {
      type: Array,
      default: () => [50, 100, 250, 500, 1000]
    },
  },
  emits: [
    'mounted',
    'refresh-data',
    'item-checked',
    'all-items-checked',
    'search-input-changed',
  ],
  data() {
    return {
      sortBy: this.defaultSortIndex ?? null,
      prevSortBy: this.defaultSortIndex ?? null,
      sortDirection: this.defaultSortDirection,
      searchInput: '',
      appliedFilters: [],
      pagination: {
        perPage: this.perPage,
        currentPage: 1,
      },
      provider: null,
    }
  },
  created() {
    this.provider = this.dataProvider === null
        ? new SimpleDataProvider(
            this.items,
            ref(this.filterConfigs),
            this.searchBy,
            new PaginationData(this.perPage, 1),
            new SortingData(
                this.defaultSortIndex ? this.columns?.[this.defaultSortIndex]?.sortBy : null,
                this.defaultSortDirection
            )
        )
        : this.dataProvider;

    if (this.dataProvider !== null) {
      if (typeof this.provider.setSearchFilterData !== "function") {
        console.error('Data provider should contain function "setSearchFilterData"');
      }
      if (typeof this.provider.getItems !== "function") {
        console.error('Data provider should contain function "getItems"');
      }
      if (typeof this.provider.getPagination !== "function") {
        console.error('Data provider should contain function "getPagination"');
      }
    }
  },
  mounted() {
    this.$emit('mounted');
  },
  methods: {
    getDataProvider() {
      return this.provider;
    },
    isStickyColumn(index) {
      return this.stickyColumns.includes(index);
    },
    equalIndexes(index1, index2) {
      index1 = index1 + '';
      index2 = index2 + '';

      return index1 === index2;
    },
    getOrientate(index) {
      let orientate = this.columns?.[index]?.orientation;
      if (!orientate) {
        if ((index + '') === '0') {
          orientate = 'left';
        } else {
          orientate = 'right';
        }
      }

      return orientate;
    },
    refreshData() {
      let pagination = new PaginationData(
        this.pagination.perPage,
        this.pagination.currentPage
      );
      pagination.setTotal(this.provider.getPagination().total);
      pagination.calculate();
      this.provider.setSearchFilterData(new SearchFilterData(
        pagination,
        new SortingData(
          this.columns?.[this.sortBy]?.sortBy,
          this.sortDirection,
          this.columns?.[this.sortBy]?.sortExportParam ?? this.columns?.[this.sortBy]?.sortBy
        ),
        this.appliedFilters,
        this.searchInput,
      ));
      this.$emit('refresh-data', this.provider.searchFilterData, this.provider);
    },
    handleItemCheck(item, isChecked) {
      item[this.checkedProp] = isChecked;
      this.$emit('item-checked', item, isChecked);
    },
    handleAllItemsCheck(isChecked) {
      if (!isChecked && this.isAllPagesChecked) {
        this.handleAllPagesCheck(isChecked);
        return;
      }

      this.preparedData.forEach(item => {
        item[this.checkedProp] = isChecked;
      });
      this.$emit('all-items-checked', isChecked);
    },
    handleAllPagesCheck(isChecked) {
      this.provider.filteredData().forEach(item => {
        item[this.checkedProp] = isChecked;
      });
      this.$emit('all-items-checked', isChecked);
    },
    clickSort(index) {
      if (this.equalIndexes(index, this.prevSortBy)) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortBy = index;
      }

      this.prevSortBy = this.sortBy;

      this.resetPagination();
    },
    searchInputChanged(inputValue) {
      this.searchInput = inputValue;
      this.$emit('search-input-changed', inputValue);

      this.resetPagination();
    },
    pageChanged(page) {
      this.pagination.currentPage = page;
      this.$el.scrollIntoView({
        behavior: 'smooth'
      });

      this.refreshData();
    },
    changePageSize(size) {
      this.pagination.perPage = size;

      this.resetPagination();
    },
    pageSizeChanged(event) {
      this.changePageSize(event.value);
    },
    resetPagination() {
      this.currentPage = 1;
      this.pagination.currentPage = 1;

      this.refreshData();
    },
    filtersApplied(filterCollectedData) {
      this.appliedFilters = filterCollectedData;

      this.resetPagination();
    },
    openPlansModal() {
      this.$store.dispatch('INVOKE_USER_LIMITS_MANUAL');
    },
  },
  computed: {
    ...mapGetters([
      'userSubscription',
    ]),
    isAllChecked() {
      return this.preparedData.every(item => item[this.checkedProp]);
    },
    isAllPagesChecked() {
      return typeof this.provider.filteredData === "function" && this.provider.filteredData().every(item => item[this.checkedProp]);
    },
    isFreeUser() {
      return this.userSubscription?.plan_name === 'Free';
    },
    activeColumns() {
      let activeColumns = {};
      Object.keys(this.columns).forEach((index) => {
        if (this.columns[index]?.hidden !== true) {
          activeColumns[index] = this.columns[index];
        }
      });

      return activeColumns;
    },
    preparedPagination() {
      return this.provider.getPagination();
    },
    preparedData() {
      return this.provider.getItems();
    },
    dataIsLoaded() {
      return !this.provider.isLoading();
    },
  },
  watch: {
    perPage() {
      this.changePageSize(this.perPage);
    },
    items() {
      if (this.dataProvider === null) {
        this.provider = new SimpleDataProvider(
          this.items,
          this.filterConfigs,
          this.searchBy,
          new PaginationData(
            this.pagination.perPage,
            this.pagination.currentPage
          ),
          new SortingData(
            this.columns?.[this.sortBy]?.sortBy,
            this.sortDirection,
            this.columns?.[this.sortBy]?.sortExportParam ?? this.columns?.[this.sortBy]?.sortBy
          )
        );
      }
    },
  }
}
</script>

<template>
  <div class="simple-info-table" :class="classes" v-if="dataIsLoaded">
    <div class="search-and-filter-block" v-show="dataIsLoaded">
      <slot name="searchFilterBlock" :provider="provider"></slot>

      <div class="filters-block" v-if="this.filterConfigs.length > 0">
        <filters-new
            ref="filtersComponent"
            v-if="filterConfigs.length > 0"
            @apply-filters="filtersApplied"
            :filters="filterConfigs"
        ></filters-new>

        <datepicker-with-range
            v-if="datePickerConfig"

            :date-filter="datePickerConfig.dateFilter"
            :min-date="datePickerConfig.minDate"
            :max-date="datePickerConfig.maxDate"
            :last-days-count="1"
            :is-active-picker="true"
            @reset-button-clicked="datePickerConfig.resetDatePicker"
            @date-filter-changed="datePickerConfig.applyDatePicker"/>

        <slot name="endOfFiltersBlock"></slot>
      </div>

      <div class="page-options">
        <div v-if="searchBy.length > 0">
          <basic-search-input :init-value="provider.getSearchFilterData().getSearchInput()"
                              placeholder="Search"
                              :hide-results-block="true"
                              :clear-input="searchInput"
                              @search-input-changed="searchInputChanged">
            <template v-slot:search-icon>
              <svg-icon v-if="provider.getSearchFilterData().getSearchInput()?.length === 0"
                        class="search-icon"
                        icon="search-solid"/>
              <svg-icon v-else
                        @click="searchInputChanged('')"
                        class="close-icon"
                        icon="close"/>
            </template>
          </basic-search-input>
        </div>
        <div class="table-options">
          <slot name="tableOptions"></slot>
          <div v-if="downloadUrl">
            <div v-if="isFreeUser"
                 @click="openPlansModal"
                 class="download-button">
              <svg-icon icon="download"/>
            </div>
            <a v-else
               :href="downloadUrl"
               class="download-button"
               download>
              <svg-icon icon="download"/>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div>
      <slot name="beforeTableBlock"></slot>
    </div>

    <div class="table-container common-white-container"
         v-if="dataIsLoaded"
         :style="tableContainerStyles">
      <slot name="containerStart"></slot>

      <template v-if="preparedData.length > 0 || !showEmptySlot">
        <div :style="tableWrapStyles" :class="tableWrapClasses">
          <table cellpadding="0" cellspacing="0">
            <thead :class="{sticky: stickyHeader}">
            <tr>
              <th v-if="multiselect" class="checkbox-column" :class="{sticky: isStickyColumn(-1)}">
                <input type="checkbox"
                       class="common-checkbox"
                       :checked="isAllChecked"
                       style="margin-top: 6px"
                       @change="(e) => this.handleAllItemsCheck(e.target.checked)"
                />
              </th>
              <th v-for="(column, index) in activeColumns" :style="column.headerStyle ?? {}" :class="{sticky: isStickyColumn(index)}">
                <div class="th_content">
                  <div class="slot_content" :class="getOrientate(index)">
                    <template v-if="column.sortBy && getOrientate(index) === 'right'">
                      <div class="sorting" @click="clickSort(index)" :class="{active: equalIndexes(sortBy, index)}">
                        <svg-icon icon="arrow-solid-up" class="icon icon-first"
                                  :class="{active: equalIndexes(sortBy, index) && sortDirection === 'asc'}"/>
                        <svg-icon icon="arrow-solid-down" class="icon"
                                  :class="{active: equalIndexes(sortBy, index) && sortDirection === 'desc'}"/>
                      </div>
                    </template>
                    <slot name="headers" :header="column.header" :index="index">
                      <div>
                        <div>
                          {{ column.header }}
                        </div>
                        <div class="sub-header" v-if="column.subHeader">
                          {{ column.subHeader }}
                        </div>
                      </div>
                    </slot>
                    <template v-if="column.sortBy && getOrientate(index) === 'left'">
                      <div class="sorting ml-7" @click="clickSort(index)" :class="{active: equalIndexes(sortBy, index)}">
                        <svg-icon icon="arrow-solid-up" class="icon icon-first"
                                  :class="{active: equalIndexes(sortBy, index) && sortDirection === 'asc'}"/>
                        <svg-icon icon="arrow-solid-down" class="icon"
                                  :class="{active: equalIndexes(sortBy, index) && sortDirection === 'desc'}"/>
                      </div>
                    </template>
                    <slot name="headersAfter" :index="index"></slot>
                  </div>
                </div>
              </th>
            </tr>
            <tr v-if="multiselect && isAllChecked && !isAllPagesChecked">
              <td :colspan="Object.keys(activeColumns).length + (multiselect ? 1 : 0)">
                <div class="selectAllPages" @click="handleAllPagesCheck">Select All Pages </div>
              </td>
            </tr>
            </thead>
            <tbody>
            <tr v-if="showTotals === 'top'">
              <td v-for="(column, hIndex) in activeColumns" :style="column?.style ?? {}">
                <div class="td-content total" :class="getOrientate(hIndex)">
                  <slot :name="`total-${hIndex}`"></slot>
                </div>
              </td>
            </tr>
            <template v-for="(item, index) in preparedData">
              <tr :class="{...trClassCallback(item), 'border-bot-none': fullWidthSection}" @click="trClickCallback(item, $event)">
                <td v-if="multiselect" class="checkbox-column" :class="{sticky: isStickyColumn(-1)}">
                  <div class="display-flex f-align-center f-j-center">
                    <input type="checkbox"
                           class="common-checkbox"
                           :disabled="item[disabledProp]"
                           :checked="item[checkedProp]"
                           @change="(e) => this.handleItemCheck(item, e.target.checked)"
                    />
                  </div>
                </td>
                <td v-for="(column, hIndex) in activeColumns" :style="column.style ?? {}" :class="{sticky: isStickyColumn(hIndex)}">
                  <div class="td-content" :class="getOrientate(hIndex)">
                    <slot :name="`items-${hIndex}`" :item="item" :index="+index" :isLast="+index === preparedData.length - 1"></slot>
                  </div>
                </td>
              </tr>
              <tr v-if="fullWidthSection">
                <td class="fullWidthSection" :colspan="Object.keys(activeColumns).length + (multiselect ? 1 : 0)">
                  <slot name="full-width-sections" :item="item" :index="index"></slot>
                </td>
              </tr>
            </template>
            <tr v-if="showTotals === 'bottom'">
              <td v-for="(column, hIndex) in activeColumns" :style="column?.style ?? {}">
                <div class="td-content total" :class="getOrientate(hIndex)">
                  <slot :name="`total-${hIndex}`"></slot>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </template>
      <div v-else>
        <slot name="emptySlot">
          <no-data-found-block>
            <template v-slot:text>
              <slot name="noDataText">
                No data that match your criteria.
              </slot>
            </template>
          </no-data-found-block>
        </slot>
      </div>

      <slot name="containerEnd"></slot>
    </div>
    <div class="simple-info-table-loading" v-else>
      <progress-indicator :loaded="dataIsLoaded">
        <slot>
          Loading...
        </slot>
      </progress-indicator>
    </div>

    <div class="pagination-wrap" v-if="dataIsLoaded && showPagination">
      <div>
        <pagination
          v-if="preparedPagination.total > preparedPagination.perPage"
          :total-pages="preparedPagination.total"
          :per-page="preparedPagination.perPage"
          :current-page="preparedPagination.currentPage"
          @page-change="pageChanged"
        />
      </div>

      <div class="rows-per-page-block">
        <div class="pages-amount">
          Showing {{ `${preparedPagination.from} - ${preparedPagination.to} of ${fakePaginationTotal ?? preparedPagination.total}` }}
        </div>

        <div class="custom-select-wrap">
          <custom-data-table-select :options="rowsPerPageList"
                                    direction="up"
                                    :initial-data="'' + preparedPagination.perPage"
                                    @selectClicked="pageSizeChanged"/>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="./styles.scss" lang="scss"></style>
