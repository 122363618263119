<template>
  <div class="radio-item"
       :class="{active: active}">
    <div class="radio-marker"></div>
    <div class="radio-text">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "RadioButton",
  props: {
    active: {
      type: Boolean
    },
  },
}
</script>

<style scoped lang="scss" src="./styles.scss"></style>