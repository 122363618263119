<template>
  <div class="custom-dropdown"
       v-click-outside="outside"
       :class="[{'active' : isOpened}, {'bg-transparent': bgIsTransparent}, 'direction-' + direction, {'global-block': dropdownInGlobalBlock}, classes]">
    <div class="select-styled"
         :class="{'filter-applied': filterApplied, 'active' : isOpened}"
         :style="{minWidth: minWidth}"
         @click="selectDropdownClicked">
      <span id="selected-value">
        <slot name="header"></slot>
      </span>
      <template v-if="withIcon">
        <div v-if="!filterApplied"
             class="icon">
          <svg-icon icon="angle-down-solid"/>
        </div>
      </template>
    </div>
    <div class="select-options-wrap"
         :style="[{minWidth: minWidth}, {width: dropdownWidth ?? 'auto'}]"
         :class="[dropdownAlign === 'left' ? 'left-side' : 'right-side', noBorder ? 'no-border' : '', withShadow ? 'with-shadow' : '']"
         ref="options-wrap">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "CustomDropdown",
  components: {},
  props: {
    options: {
      type: Object
    },
    classes: {
      type: String
    },
    initialData: {
      type: String
    },
    bgIsTransparent: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: 'down'
    },
    dropdownAlign: {
      type: String,
      default: 'left'
    },
    minWidth: {
      type: String
    },
    dropdownWidth: {
      type: String
    },
    presetsData: {
      type: Object,
      default: () => ({
        'Top 3': {
          min: 1,
          max: 3
        },
        'Top 5': {
          min: 1,
          max: 5
        },
        'Top 10': {
          min: 1,
          max: 10
        },
        'Top 30': {
          min: 1,
          max: 30
        },
        'Top 50': {
          min: 1,
          max: 50
        },
      })
    },
    dataUpdated: {
      required: false
    },
    dropdownInGlobalBlock: {
      type: Boolean,
      default: false
    },
    withIcon: {
      type: Boolean,
      default: true
    },
    withShadow: {
      type: Boolean,
      default: false
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    closeDropdown: {
      type: Number,
      required: false
    },
  },
  emits: ['filter-applied', 'dropdown-closed', 'dropdown-opened', 'dropdown-clicked-outside'],
  data() {
    return {
      isOpened: false,
      appliedPreset: {},
      checkedRadio: null,
      presetsInnerData: {},
      filterApplied: false,
      selectedPreset: {
        min: 1,
        max: 100
      }
    }
  },
  methods: {
    outside() {
      this.isOpened = false;
      this.$emit('dropdown-clicked-outside');
    },
    selectDropdownClicked(e) {
      if (e?.target?.classList?.contains('icon') || e?.target?.classList?.contains('applied-icon') || e?.target?.closest('.svg-icons')?.classList?.contains('applied-icon')) {
        if (this.isOpened) {
          this.isOpened = true;
        }
      } else {
        this.isOpened = !this.isOpened;
        if (this.isOpened === false) {
          this.$emit('dropdown-closed');
        }
      }

      if (this.isOpened) {
        this.$emit('dropdown-opened');
      }
    }
  },
  mounted() {
    if (this.initialData) {
      // this.selectedValue = this.initialData;
    }

    if (this.presetsData) {
      this.presetsInnerData = {...this.presetsData};
    }
  },
  computed: {},
  watch: {
    initialData(val) {
      // if (val) {
      //   this.selectedValue = val;
      // }
    },
    dataUpdated: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.isOpened = false;
        }
      },
      deep: true
    },
    closeDropdown(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.isOpened = false;
      }
    }
  }
}
</script>

<style scoped src="./styles.scss" lang="scss"></style>