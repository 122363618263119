<template>
  <div class="search-input-block"
       :class="{active: searchInput.length > 0 && appSearchLoaded}"
       v-click-outside="outside">
    <slot name="searchInputBlock"></slot>
    <div class="input-wrap">
      <form>
        <label>
          <input type="text"
                 class="search-input"
                 :class="{focused: isFocused, loading: searchInput.length > 0 && !appSearchLoaded}"
                 @keydown.enter.prevent
                 ref="searchInput"
                 :placeholder="isFocused ? placeholder : ''"
                 @focusin="inputFocusIn"
                 v-model="searchInput">

          <span class="selected-app-block"
                v-show="!isFocused" v-if="showCurrent">
            <span class="product-logo">
              <template v-if="selectedAppLocal?.logo">
                <app-image
                    :width="20"
                    :height="20"
                    :src="selectedAppLocal?.logo"
                />
              </template>
              <template v-else>
                <div class="empty-logo"></div>
              </template>
            </span>

            <span class="product-name">
              {{ selectedAppLocal?.title ? decodeURI(selectedAppLocal?.title) : '' }}
              <span class="store-icon">
                <img v-if="selectedAppLocal?.store?.key === 'APP_STORE'"
                     src="@/assets/images/icons/app_store.png"
                     alt="Store icon"
                     class="store-icon"
                     :width="13"
                     :height="13">
                <img v-else
                     src="@/assets/images/icons/google_play.png"
                     alt="Store icon"
                     class="store-icon"
                     :width="13"
                     :height="13">
              </span>
            </span>
          </span>
          <span class="select_other" v-else>
            <slot name="selectOther">
              Select Other App from Store
            </slot>
          </span>
        </label>
      </form>
      <svg-icon class="search-icon"
                icon="search-solid"/>
    </div>

    <div class="results-block-wrap" v-if="searchInput?.length > 0 || showResultsAnyway">
      <template v-if="!appSearchLoaded">
        <div class="preloader-block">
          <custom-preloader height="20px"/>
        </div>
      </template>
      <template v-else-if="searchResults?.length > 0">
        <div class="results-block"
             :style="{'max-height': resultsMaxHeight}">
          <slot name="aboveResults"></slot>
          <div class="result-item"
               v-for="result in searchResults">
            <slot name="results"
                  :result="result"></slot>
          </div>
        </div>
      </template>
      <template v-else-if="searchInput?.length > 0">
        <div class="no-results">
          {{
            searchResults?.message ?? "We couldn't find what you are looking for"
          }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Preloader from '@/components/UI/Preloader/index';
import {debounce} from "@/utils/utils";
import AppImage from "@/components/UI/ImageLazyLoad/index.vue";

export default {
  name: "SearchAppInput",
  props: {
    showCurrent: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String
    },
    classes: {
      type: Array,
      required: false,
      default: () => []
    },
    searchResults: {
      type: Object
    },
    dataUpdated: {
      type: Number,
      required: false
    },
    appSearchLoaded: {
      type: Boolean,
      default: false
    },
    selectedApp: {
      type: Object
    },
    resultsMaxHeight: {
      type: String,
      default: '256px'
    },
    showResultsAnyway: {
      type: Boolean,
      default: false
    },
  },
  emits: ['search-input-changed', 'app-clicked', 'enter-pressed', 'outside', 'focus-in'],
  components: {
    AppImage,
    'custom-preloader': Preloader
  },
  data() {
    return {
      searchInput: '',
      debouncedInput: '',
      searchIsActive: false,
      isFocused: false,
      selectedAppLocal: {},
    }
  },
  methods: {
    outside() {
      this.isFocused = false;
      this.searchInput = '';
      this.$emit('outside');
    },
    inputFocusIn() {
      this.isFocused = true;
      this.$emit('focus-in');
    },
  },
  mounted() {
    setTimeout(() => {
      this.selectedAppLocal = {...this.selectedApp};
    }, 350);

  },
  watch: {
    searchInput: debounce(function (newVal) {
      this.debouncedInput = newVal;
      this.$emit('search-input-changed', this.debouncedInput);
    }, 500),
    dataUpdated(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.searchInput = '';
      }
    },
    selectedApp(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.searchInput = '';
        this.selectedAppLocal = {...this.selectedApp};
      }
    },
  }
}
</script>

<style lang="scss" src="./styles.scss" scoped></style>
<style scoped>
.select_other {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: var(--neutral-700);
  position: absolute;
  left: 25px;
  top: 6px;
}
form {
  background-color: #fff;
  border-radius: 8px;
}

.results-block {
  padding: 0 !important;
}
.result-item {
  padding: 5px 8px;
  margin-bottom: 0 !important;

  &:hover {
    background-color: #f5f8fd;
  }
}
</style>