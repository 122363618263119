<template>
  <div class="subscribe-plans">
    <template v-if="loaded">
      <slot>
        <div class="heading-info">Choosing one of the plans will allow you to take advantage of all the features of
          Asolytics:
        </div>
      </slot>

      <div class="trigger-container">
        <div class="switch-trigger-block">
          <switch-toggle @toggle-changed="toggleChanged"
                         option-one="Monthly"
                         option-two="Annual"/>
        </div>
        <div class="discount-block">
          Save <span class="value js-discount-value">30</span>%
        </div>
      </div>

      <div class="plans-cols">
        <div class="col white">
          <div class="plan-block-content">
            <div class="plan-heading">
              <div class="plan-name">Free</div>
              <div class="plan-price-block">
                <div class="price">$0</div>
              </div>
            </div>
            <div class="plan-description">Increase App Downloads</div>
            <div class="plan-features">
              <div class="features-group">
                <div class="feature-item">
                  <img src="@/assets/images/icons/icon-check-green.svg"
                       width="16"
                       height="12"
                       class="feature-icon"
                       alt="Check icon"/>
                  <div
                   class="feature-text">{{ plansLimits['FREE']?.max_apps?.total ?? '' }}
                    Apps
                  </div>
                </div>
                <div class="feature-item">
                  <img src="@/assets/images/icons/icon-check-green.svg"
                       width="16"
                       height="12"
                       class="feature-icon"
                       alt="Check icon"/>
                  <div
                   class="feature-text">{{ plansLimits['FREE']?.max_keywords?.total ?? '' }}
                    Keywords
                  </div>
                </div>
                <div class="feature-item">
                  <img src="@/assets/images/icons/icon-check-green.svg"
                       width="16"
                       height="12"
                       class="feature-icon"
                       alt="Check icon"/>
                  <div
                   class="feature-text">{{ plansLimits['FREE']?.max_competitors?.total ?? '' }}
                    Competitors/app
                  </div>
                </div>
                <div class="feature-item">
                  <img src="@/assets/images/icons/x-alt.svg"
                       width="24"
                       height="24"
                       class="feature-icon close"
                       alt="Check icon"/>
                  <div class="feature-text">Unlimited Keyword Autosuggest</div>
                </div>
                <div class="feature-item">
                  <img src="@/assets/images/icons/x-alt.svg"
                       width="24"
                       height="24"
                       class="feature-icon close"
                       alt="Check icon"/>
                  <div class="feature-text">Unlimited Ranking Keywords</div>
                </div>
                <div class="feature-item">
                  <img src="@/assets/images/icons/x-alt.svg"
                       width="24"
                       height="24"
                       class="feature-icon close"
                       alt="Check icon"/>
                  <div class="feature-text">Unlimited Recommended Keywords</div>
                </div>
                <div class="feature-item">
                  <img src="@/assets/images/icons/x-alt.svg"
                       width="24"
                       height="24"
                       class="feature-icon close"
                       alt="Check icon"/>
                  <div class="feature-text">Meta Editor</div>
                </div>
                <div class="feature-item">
                  <img src="@/assets/images/icons/x-alt.svg"
                       width="24"
                       height="24"
                       class="feature-icon close"
                       alt="Check icon"/>
                  <div class="feature-text">App Insights</div>
                </div>
              </div>
            </div>
          </div>
          <div class="inner-content">
            <template v-if="userData?.is_confirmed_some_plan === false">
              <button class="btn btn-default"
                      @click="freeButtonClickHandler">Start
              </button>
            </template>
          </div>
        </div>

        <div class="col white">
          <div class="plan-block-content">
            <div class="plan-heading">
              <div class="plan-name">Pro</div>
              <div class="plan-price-block">
                <div class="old-price">
                  <template v-if="period !== 'month'">${{ formatNumbers(plansPrices['PRO']?.pro_month?.amount ?? 0) }}
                  </template>
                </div>
                <div class="plan-price">
                  <span class="price">
                      <template v-if="period === 'month'">${{
                          formatNumbers(plansPrices['PRO']?.pro_month?.amount ?? 0)
                        }}</template>
                    <!--                      <template v-else>${{ formatNumbers(plansPrices['PRO']?.pro_month?.amount ?? 0) }} </template>-->
                      <template v-else>$139</template>
                  </span>
                </div>
                <span class="period-block">
                  <span class="period annual">
                      /month
                    </span>
                  </span>
              </div>
            </div>
            <div class="plan-description">Grow faster with advanced keywords insights and META Editor</div>
            <div class="plan-features">
              <div class="features-group">
                <div class="feature-item">
                  <img src="@/assets/images/icons/icon-check-green.svg"
                       width="16"
                       height="12"
                       class="feature-icon"
                       alt="Check icon"/>
                  <div class="feature-text">
                    {{ plansLimits['PRO']?.max_apps?.total ?? '' }}
                    Apps
                  </div>
                </div>
                <div class="feature-item">
                  <img src="@/assets/images/icons/icon-check-green.svg"
                       width="16"
                       height="12"
                       class="feature-icon"
                       alt="Check icon"/>
                  <div
                   class="feature-text">{{
                      plansLimits['PRO']?.max_keywords.total ? formatNumbers(plansLimits['PRO']?.max_keywords.total) : ''
                    }}
                    Keywords
                  </div>
                </div>
                <div class="feature-item">
                  <img src="@/assets/images/icons/icon-check-green.svg"
                       width="16"
                       height="12"
                       class="feature-icon"
                       alt="Check icon"/>
                  <div
                   class="feature-text">
                    Unlimited Competitors
                  </div>
                </div>
                <div class="feature-item">
                  <img src="@/assets/images/icons/icon-check-green.svg"
                       width="16"
                       height="12"
                       class="feature-icon"
                       alt="Check icon"/>
                  <div class="feature-text">Unlimited Keyword Autosuggest</div>
                </div>
                <div class="feature-item">
                  <img src="@/assets/images/icons/icon-check-green.svg"
                       width="16"
                       height="12"
                       class="feature-icon"
                       alt="Check icon"/>
                  <div class="feature-text">Unlimited Ranking Keywords</div>
                </div>
                <div class="feature-item">
                  <img src="@/assets/images/icons/icon-check-green.svg"
                       width="16"
                       height="12"
                       class="feature-icon"
                       alt="Check icon"/>
                  <div class="feature-text">Unlimited Recommended Keywords</div>
                </div>
                <div class="feature-item">
                  <img src="@/assets/images/icons/icon-check-green.svg"
                       width="16"
                       height="12"
                       class="feature-icon"
                       alt="Check icon"/>
                  <div class="feature-text">Meta Editor</div>
                </div>
                <div class="feature-item">
                  <img src="@/assets/images/icons/icon-check-green.svg"
                       width="16"
                       height="12"
                       class="feature-icon"
                       alt="Check icon"/>
                  <div class="feature-text">App Insights</div>
                </div>
              </div>
            </div>
          </div>

          <div class="inner-content">
            <template v-if="currentPlan?.key === 'PRO'">
              <div v-if="currentPlan?.price?.interval === period"
                   class="active-plan">
                Current
              </div>
              <a v-else
                 :href="subscriptionRoute('pro')"
                 class="btn btn-default"
                 target="_blank">Select
              </a>
            </template>
            <template v-else>
              <a :href="subscriptionRoute('pro')"
                 class="btn btn-default"
                 target="_blank">{{
                  (plansPrices['PRO']?.['pro_' + period]?.trial_period_days ?? null) === true ? '7-Days Trial' : 'Select'
                }}
              </a>
            </template>
          </div>
        </div>

        <div class="col white">
          <div class="plan-block-content">
            <div class="plan-heading">
              <div class="plan-name">Custom</div>
            </div>
            <div class="plan-description">Request packages and tailored solutions to your needs
            </div>
            <div class="plan-features">
              <div class="features-group">
                <div class="feature-item">
                  <img src="@/assets/images/icons/icon-check-green.svg"
                       width="16"
                       height="12"
                       class="feature-icon"
                       alt="Check icon"/>
                  <div class="feature-text">Access to ASO Consulting</div>
                </div>
                <div class="feature-item">
                  <img src="@/assets/images/icons/icon-check-green.svg"
                       width="16"
                       height="12"
                       class="feature-icon"
                       alt="Check icon"/>
                  <div class="feature-text">Priority Assistance in Keyword Autosuggest</div>
                </div>
                <div class="feature-item">
                  <img src="@/assets/images/icons/icon-check-green.svg"
                       width="16"
                       height="12"
                       class="feature-icon"
                       alt="Check icon"/>
                  <div class="feature-text">Dedicated Account Manager in Recommended
                    Keywords
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a class="btn btn-default"
             :href="env + 'pricing?source=plans-modal'"
             target="_blank">Send request
          </a>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="preloader-wrap">
        <preloader-table-small loader-size="50px"></preloader-table-small>
      </div>
    </template>

    <custom-modal :opened="showModal"
                  :max-width="728"
                  :max-height="'initial'"
                  class-name="quote-request-modal"
                  @closeModal="closeModal">
      <div class="modal-title">Request a Quote</div>
      <div class="modal-message textarea-block">
        <form action="/api/tickets"
              class="book-a-demo-form"
              method="post">
          <div class="form-row double">
            <div class="form-row-content w50p">
              <div class="form-label">First Name*</div>
              <div class="form-control">
                <label><input type="text"
                              name="author_name"
                              placeholder="Ronald"
                              :class="{error: modalFormData.author_email === '' && formSubmitClicked}"
                              v-model="modalFormData.author_name"
                              required></label>

                <div class="error-message"
                     :class="{active: modalFormData.author_name === '' && formSubmitClicked}">First name is required
                </div>
              </div>
            </div>

            <div class="form-row-content w50p">
              <div class="form-label">Last Name*</div>
              <div class="form-control">
                <label><input type="text"
                              name="author_surname"
                              placeholder="Richards"
                              :class="{error: modalFormData.last_name === '' && formSubmitClicked}"
                              v-model="modalFormData.last_name"
                              required></label>
                <div class="error-message"
                     :class="{active: modalFormData.last_name === '' && formSubmitClicked}">Last name is required
                </div>
              </div>
            </div>
          </div>

          <div class="form-row double">
            <div class="form-row-content w50p">
              <div class="form-label">Email*</div>
              <div class="form-control">
                <label><input type="email"
                              name="author_email"
                              placeholder="mailto:michael.mitc@example.com"
                              :class="{error: modalFormData.author_email === '' && formSubmitClicked}"
                              maxlength="50"
                              v-model="modalFormData.author_email"
                              required></label>
                <div class="error-message"
                     :class="{active: modalFormData.author_email === '' && formSubmitClicked}">Email is required
                </div>
              </div>
            </div>

            <div class="form-row-content w50p">
              <div class="form-label">Phone*</div>
              <div class="form-control">
                <label><input type="text"
                              name="phone"
                              placeholder="(405) 555-0128"
                              :class="{error: modalFormData.author_phone === '' && formSubmitClicked}"
                              v-model="modalFormData.author_phone"
                              required></label>
                <div class="error-message"
                     :class="{active: modalFormData.author_phone === '' && formSubmitClicked}">Phone is required
                </div>
              </div>
            </div>
          </div>

          <div class="form-row w100p">
            <div class="form-row-content">
              <div class="form-label">Company Name*</div>
              <div class="form-control">
                <label><input type="text"
                              name="company_name"
                              placeholder="General Electric"
                              :class="{error: modalFormData.company_name === '' && formSubmitClicked}"
                              v-model="modalFormData.company_name"
                              required></label>
                <div class="error-message"
                     :class="{active: modalFormData.company_name === '' && formSubmitClicked}">Company name is required
                </div>
              </div>
            </div>
          </div>

          <div class="form-row double">
            <div class="form-row-content pos-relative w50p">
              <div class="form-label">Company size (number of employees)*</div>
              <div class="form-control">
                <custom-select :options="employeesArray"
                               :with-outside-logic="true"
                               :classes="`subscribe-select ${(modalFormData.company_size === '' && formSubmitClicked) ? 'error' : ''}`"
                               :outside-selected-value="modalFormData.company_size">
                  <template v-slot:results="results">
                    <div v-for="result in results"
                         @click="companySizeSelectHandler(result)"
                         class="result-item">
                      {{ result }}
                    </div>
                  </template>
                </custom-select>
                <div class="error-message"
                     :class="{active: modalFormData.company_size === '' && formSubmitClicked}">Company size is required
                </div>
              </div>
            </div>

            <div class="form-row-content pos-relative w50p">
              <div class="form-label">Country*</div>
              <div class="form-control">
                <custom-select :options="searchCountryResults"
                               :with-outside-logic="true"
                               :outside-selected-value="modalFormData.country_name"
                               :classes="`subscribe-select ${(modalFormData.country_name === '' && formSubmitClicked) ? 'error' : ''}`">
                  <template v-slot:results="results">
                    <div v-for="result in results"
                         @click="countrySelectHandler(result)"
                         class="result-item">
                      <dynamic-image v-if="result?.code !== 'WORLDWIDE'"
                                     classes="country-flag"
                                     :width="18"
                                     :height="14"
                                     :size="32"
                                     :country-code="result?.code"/>
                      <span class="product-name">
                          {{ result?.name }}
                        </span>
                    </div>
                  </template>
                </custom-select>

                <div class="error-message"
                     :class="{active: modalFormData.country_name === '' && formSubmitClicked}">Country name is required
                </div>
              </div>
            </div>
          </div>

          <div class="form-row w100p">
            <div class="form-row-content">
              <div class="form-label">Message</div>
              <div class="form-control">
                <label>
                <textarea name="content"
                          placeholder="Please share with us more details about your needs and how our services can assist you."
                          :class="{error: modalFormData.content === '' && formSubmitClicked}"
                          v-model="modalFormData.content"
                          required></textarea>
                </label>
                <div class="error-message"
                     :class="{active: modalFormData.content === '' && formSubmitClicked}">Message is required
                </div>
              </div>
            </div>
          </div>

          <div class="form-row w100p mar-b-24">
            <div class="form-row-content">
              <div class="checkbox-block"
                   style="margin-bottom: 4px;">
            <span class="custom-table-checkbox">
                  <input type="checkbox"
                         class="agree-checkbox"
                         id="quote-agree"
                         v-model="modalFormData.policy_agree"
                         required
                         value="agreement">

                    <label class="checkbox-label"
                           for="quote-agree">
                        <span class="text">
                         By submitting, you confirm that you have read and understood Asolytics <a
                         :href="env + 'privacy-policy'"
                         target="_blank">Privacy Policy</a>
                        </span>
                    </label>

                </span>
              </div>
              <div class="error-message"
                   :class="{active: modalFormData.policy_agree === false && formSubmitClicked}"
                   style="padding-left: 24px;">Please accept Asolytics Privacy Policy
              </div>

              <div class="checkbox-block"
                   style="margin-bottom: 4px;">
                <span class="custom-table-checkbox">
                      <input type="checkbox"
                             class="agree-checkbox"
                             id="subscribe-agree"
                             required
                             v-model="modalFormData.subscription_agree"
                             name="subscribe"
                             value="subscribe">

                        <label class="checkbox-label"
                               for="subscribe-agree">
                            <span class="text">
                             I would like to subscribe to Asolytics Newsletter with tips on App Stores optimization
                            </span>
                        </label>

                </span>
              </div>

              <div class="checkbox-block">
                <div style="padding-left: 24px;">P.S.: We promise not to spam and you can always unsubscribe 😊</div>
              </div>
            </div>
          </div>

          <div class="form-row">
            <button type="button"
                    class="btn btn-default btn-rounded send-pricing-request-btn"
                    @click="submitModalForm">Get a Quote
            </button>

            <div class="success-message"
                 v-if="formSendSuccessfully">
              Success! We received your request and we'll contact you shortly 😊
            </div>
          </div>
        </form>

      </div>
    </custom-modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SwitchToggle from "@/components/UI/SwitchToggle";
import {httpRequest} from "@/api";
import CustomDropdown from "@/components/UI/CustomDropdown/index";
import CustomSelect from "@/components/Forms/CustomSelect/index.vue";
import CustomSelectWithSearch from "@/components/Forms/CustomSelectWithSearch/index.vue";

export default {
  name: "SubscribePlans",
  components: {
    'custom-select-search': CustomSelectWithSearch,
    'switch-toggle': SwitchToggle,
    'custom-dropdown': CustomDropdown,
    'custom-select': CustomSelect,
  },
  data() {
    return {
      env: process.env.VUE_APP_URL,
      csrfToken: null,
      period: 'month',
      plansLimits: {},
      plansPrices: {},
      currentPlan: {},
      dropdownSelectedPlanName: '',
      loaded: false,
      formSubmitClicked: false,
      planChanged: 0,
      showModal: false,
      formSendSuccessfully: false,
      searchCountryResults: [],
      employeesArray: [
        "1-5 employees",
        "6-10 employees",
        "11-50 employees",
        "51-100 employees",
        "101-500 employee",
        "501+ employee",
      ],
      modalFormData: {
        author_name: '',
        last_name: '',
        author_email: '',
        author_phone: '',
        content: '',
        company_size: '',
        country_name: '',
        company_name: '',
        policy_agree: false,
        subscription_agree: false,
      }
    }
  },
  async mounted() {
    await this.constructPlansLimit();
    await this.fetchCurrentPlan();
    await this.fetchPlanPrices();
    await this.fetchCountriesList();
    this.dropdownSelectedPlanName = this.currentPlan?.key;
  },
  methods: {
    closeModal() {
      this.showModal = false;
      this.formSendSuccessfully = false;
      this.formSubmitClicked = false;

      this.modalFormData = {
        author_name: '',
        last_name: '',
        author_email: '',
        author_phone: '',
        content: '',
        company_size: '',
        country_name: '',
        company_name: '',
        policy_agree: false,
        subscription_agree: false,
      };
    },
    async constructPlansLimit() {
      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.user.PLANS_LIMITS);
      this.plansLimits = {...result};
    },
    async fetchPlanPrices() {
      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.user.PLANS_PRICES);
      this.plansPrices = {...result};
    },
    async fetchCurrentPlan() {
      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.user.USER_CURRENT_PLAN);
      this.currentPlan = {...result};
      this.loaded = true;
    },
    toggleChanged(event) {
      if (event === false) {
        this.period = 'month';
      } else {
        this.period = 'year';
      }
    },
    dropdownClosed() {
      this.planChanged++;
    },
    planItemClicked(planName) {
      this.dropdownSelectedPlanName = planName;
      this.dropdownClosed();
    },
    formatNumbers(value) {
      if (!value) {
        return value;
      } else {
        return value.toLocaleString('ru-RU', {maximumFractionDigits: 0})
      }
    },
    subscriptionRoute(plan = '') {
      const path = this.dropdownSelectedPlanName === 'FREE' ? 'subscription' : 'subscription/update';
      return this.env + path + '?plan=' + plan + '_' + this.period;
    },
    companySizeSelectHandler(e) {
      this.modalFormData.company_size = e;
    },
    async countrySelectHandler(e) {
      this.modalFormData.country_name = e?.name;
      this.formSubmitClicked = false;
    },
    async fetchCountriesList(value) {
      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.SEARCH_LOCALES);
      this.searchCountryResults = result?.countries?.filter(item => item?.code !== 'WORLDWIDE');
      this.formSubmitClicked = false;
    },
    allFormFieldsFilled() {
      return this.modalFormData.author_name !== ''
       && this.modalFormData.last_name !== ''
       && this.modalFormData.author_email !== ''
       && this.modalFormData.author_phone !== ''
       && this.modalFormData.company_name !== ''
       && this.modalFormData.company_size !== ''
       && this.modalFormData.country_name !== ''
       && this.modalFormData.content !== ''
       && this.modalFormData.policy_agree !== false;
    },
    async submitModalForm() {
      this.formSubmitClicked = true;

      const formdata = new FormData();
      formdata.append("type", "order-aso-asa-services");
      formdata.append("author_name", this.modalFormData.author_name);
      formdata.append("author_email", this.modalFormData.author_email);
      formdata.append("author_phone", this.modalFormData.author_phone);
      formdata.append("from_url", window.location.origin + this.$route?.path);

      const extendedContent = `\n last name: ${this.modalFormData.last_name},\n company_name: ${this.modalFormData.company_name},\n company_size: ${this.modalFormData.company_size},\n country_name: ${this.modalFormData.country_name},\n content: ${this.modalFormData.content},\n subscribeAgree: ${this.modalFormData.subscription_agree},`;

      formdata.append("content", extendedContent);

      if (this.allFormFieldsFilled()) {
        const result = await httpRequest('POST', process.env.VUE_APP_API_URL + this.$ApiUrls.user.CONTACTS, formdata);

        if (!result?.errors && !result?.message) {
          this.formSendSuccessfully = true;
        } else {
          this.formSendSuccessfully = false;
        }

        this.formSubmitClicked = false;
      }
    },
    async freeButtonClickHandler() {
      await httpRequest('GET', process.env.VUE_APP_API_URL + 'api/subscription/select-free');

      const userData = {...this.userData};
      userData.is_confirmed_some_plan = true;
      this.$store.dispatch('SET_USER_DATA', userData);

      this.$router.push({name: 'AddFirstAppPage'});
    },
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'userData',
    ])
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>