export default {
    trackedKeywords: state => {
        return state.trackedKeywords
    },
    suggestedKeywords: state => {
        return state.suggestedKeywords
    },
    sortingMeta: state => {
        return state.sortingMeta;
    },
    urlQueryString: state => {
        return state.urlQueryString;
    },
    liveSearchResults: state => {
        return state.liveSearchResults;
    },
    popularityChartResults: state => {
        return state.popularityChartResults;
    },
    competitorsResults: state => {
        return state.competitors;
    },
    trackingKeywordsCount: state => {
        return state.trackingKeywordsCount;
    },
    currentWordSelected: state => {
        return state.currentWordSelected;
    },
    uncoveredWordsState: state => {
        return state.uncoveredWordsState;
    },
};