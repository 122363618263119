export default {
    GET_APP_EDITABLE_METAS: 'api/meta-creator/get-app-editable-metas',
    SAVE_APP_METAS: 'api/meta-creator/save-metas',
    GET_APP_METAS: 'api/meta-creator/get-saved-metas',
    GET_WORDS: 'api/meta-creator/get-words',
    GET_KEYWORDS: 'api/meta-creator/get-keywords',
    GET_UNCOVERED_KEYWORDS: 'api/meta-creator/get-dropped-keywords',
    GET_COMPETITORS: 'api/meta-creator/get-competitors-meta',
    DELETE_KEYWORDS: 'api/meta-creator/delete-keywords',
    DELETE_SAVED_META: 'api/meta-creator/delete-saved-metas',
};