export default {
    SET_SORTING_META({commit, state}, payload) {
        let metaObject = {};

        for (const key in state.CONST) {
            metaObject[state.CONST[key]] = payload[state.CONST[key]] === null ? null : (payload[state.CONST[key]] ?? state.sortingMeta[state.CONST[key]]);
        }

        commit('SET_SORTING_META', metaObject);
    },
    CONSTRUCT_URL_QUERY_STRING({commit, state}, payload) {
        let queryString = '';
        if (payload && typeof payload === 'string') {
            commit('SET_URL_QUERY_STRING', payload);
            return;
        }

        if (Object.keys(state.sortingMeta).length > 0) {
            for (const key in state.sortingMeta) {
                if (state.sortingMeta[key] !== null && state.sortingMeta[key] !== undefined) {
                    queryString += '&' + key + '=' + state.sortingMeta[key];
                }
            }
        }

        commit('SET_URL_QUERY_STRING', queryString);
    },
    RESET_SORTING_META({commit, state, rootState}, payload) {
        let metaObject = {...state.sortingMeta};

        const keys = ['sort_by', 'sort_direction', 'page', 'per_page'];

        for (const key in metaObject) {
            if (!keys?.includes(key)) {
                metaObject[key] = undefined;
            } else {
                metaObject[key] = payload[key];
            }
        }

        commit('SET_SORTING_META', metaObject);
    },
    CURRENT_WORD_SELECTED({commit, state, rootState}, payload) {
        commit('CURRENT_WORD_SELECTED', payload);
    },
    SET_UNCOVERED_WORDS({commit, state, rootState}, payload) {
        commit('SET_UNCOVERED_WORDS', payload);
    },
};
